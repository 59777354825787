import { action, autorun, computed, IObservableArray, observable } from 'mobx';
import {
  addDays,
  addMonths,
  endOfMonth,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns';

import {
  DetailedManualPoint,
  getSettings,
  getStudent,
  getManualPoints,
  Student,
  ManualPoint,
  changePoints,
} from '../lib/client';
import { AbstractStore } from './abstract.store';
import { getEndOfWeek, getStartOfWeek, parseDate } from '../lib/date.utils';

interface Criteria {
  startDate: Date;
  endDate: Date;
  selectedDate: Date;
  id: number | undefined;
}

class StudentStore extends AbstractStore {
  private timeout: number | undefined;

  @observable.deep current: Student | undefined = undefined;
  @observable loading = false;
  @observable.deep criteria: Criteria = {
    selectedDate: addDays(startOfMonth(startOfDay(new Date())), 15),
    startDate: startOfMonth(startOfDay(new Date())),
    endDate: endOfMonth(startOfDay(new Date())),
    id: undefined,
  };
  @observable settings: Map<string, any> = observable.map({});
  @observable points: IObservableArray<DetailedManualPoint> = observable.array(
    [],
  );

  public init() {
    this.disposers.push(
      /*
      reaction(
        () => [this.criteria.id, this.root.authStore.isLoggedIn],
        () => this.load(),
        { name: 'student.store - reload current' },
      ),
*/
      autorun(async () => {
        if (!this.root.authStore.isLoggedIn || !this.criteria.id) {
          this.current = undefined;
          this.criteria.id = undefined;
          return;
        }

        await this.load();
      }),
    );
  }

  @action async load(): Promise<void> {
    if (this.criteria.id === undefined) {
      return;
    }

    if (!this.root.authStore.isLoggedIn) {
      return;
    }

    this.startLoading();

    const id = Number(this.criteria.id);
    try {
      const student = await getStudent(id);
      await this.loadPoints();
      if (student) {
        this.setCurrent(student);
      }
      const settings = await getSettings(id);
      if (settings) {
        this.setSettings(settings);
      }
    } catch (e) {
      console.error(e);
    }

    this.stopLoading();
  }

  @action async loadPoints(): Promise<void> {
    const id = Number(this.criteria.id);
    if (!id) {
      return;
    }
    const points = (await getManualPoints(id)) || [];
    this.setPoints(points);
  }

  @action startLoading(): void {
    this.loading = true;
  }

  @action stopLoading(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(
      action(() => {
        this.loading = false;
      }),
      500,
    );
  }

  @action setCurrent(student: Student | undefined): void {
    this.settings.clear();
    this.current = student;
  }

  @action setId(currentId?: number): void {
    this.criteria.id = currentId;
  }

  @action nextMonth(): void {
    this.selectMonthByDate(addMonths(this.criteria.selectedDate, 1));
  }

  @action prevMonth(): void {
    this.selectMonthByDate(subMonths(this.criteria.selectedDate, 1));
  }

  @action includeWeek(selected: Date): void {
    if (selected < this.criteria.startDate) {
      this.criteria.startDate = getStartOfWeek(selected);
    } else if (selected > this.criteria.endDate) {
      this.criteria.endDate = getEndOfWeek(selected);
    }
  }

  @action selectMonthByDate(date: Date): void {
    const startDate = getStartOfWeek(startOfMonth(startOfDay(date)));
    const endDate = getEndOfWeek(endOfMonth(startOfDay(date)));
    if (this.criteria.selectedDate !== date) {
      this.criteria.selectedDate = date;
    }
    if (this.criteria.startDate !== startDate) {
      this.criteria.startDate = startDate;
    }
    if (this.criteria.endDate !== endDate) {
      this.criteria.endDate = endDate;
    }
  }

  @computed get isLoading() {
    return (
      this.loading ||
      this.root.dailyStore.loading ||
      this.root.weeklyStore.loading
    );
  }

  @computed get manualPoints(): DetailedManualPoint[] {
    return this.points.toJS();
  }

  @action setSettings(settings: Record<string, any>): void {
    for (const key of Object.keys(settings)) {
      this.settings.set(key, settings[key]);
    }
    this.root.dailyStore.reload();
  }

  @action setPoints(points: DetailedManualPoint[]): void {
    this.points.clear();
    this.points.push(...points);
  }

  @computed get minDate(): Date {
    if (this.settings.has('startDate')) {
      return parseDate(this.settings.get('startDate'));
    }

    return new Date();
  }

  @computed get maxDate(): Date {
    if (this.settings.has('endDate')) {
      return parseDate(this.settings.get('endDate'));
    }

    return new Date();
  }

  @action async changeScore(selectedPoints: ManualPoint): Promise<void> {
    if (this.current?.id === undefined) {
      return;
    }
    this.startLoading();
    try {
      await changePoints(this.current?.id, selectedPoints);
      await this.loadPoints();
    } catch (e) {
      console.error(e);
    }
    this.stopLoading();
  }
}

export { StudentStore };

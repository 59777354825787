import React, {
  ReactElement,
  ReactNode,
  MouseEvent,
  FunctionComponent,
} from 'react';
import { useIntl } from 'react-intl';
import { format, eachDayOfInterval, isSameDay, isYesterday } from 'date-fns';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Popover, Position } from '@blueprintjs/core';

import { generateRoute } from '../../routes';
import { DailyStore } from '../../stores/daily.store';
import {
  formatDate,
  formatDay,
  getEndOfWeek,
  getStartOfWeek,
  getWeekNumber,
} from '../../lib/date.utils';
import { useStores } from '../../stores';
import { resolveDailyMode } from './functions';
import { formatMessage } from '../../translations';

import './index.scss';

export type OnDateSelect = undefined | null | ((selected: Date) => void);
export type DayMode = 'locked' | 'missing' | 'done';
export type DayProps = {
  date: Date;
  onSelect?: OnDateSelect;
  mode?: DayMode;
};

export const Day = observer(function Day(props: DayProps) {
  const { push } = useHistory();
  const {
    date,
    mode,
    onSelect = (selected: Date) => {
      push(generateRoute('studentSubmitForm', { date: formatDate(selected) }));
    },
  } = props;
  const today = new Date();
  const after = date < today;
  const current = isSameDay(date, today);
  const yesterday = isYesterday(date);
  const locked = mode === 'locked';
  const missing = mode === 'missing';
  const done = mode === 'done';

  function onClick(event: MouseEvent): void {
    event.preventDefault();
    if (typeof onSelect === 'function') {
      onSelect(date);
    }
  }

  if (locked) {
    return (
      <div className="Day Day--locked" onClick={onClick}>
        {format(date, 'd')}
      </div>
    );
  }

  return (
    <button
      onClick={onClick}
      className={classNames('Day', {
        'Day--after': after,
        'Day--current': current,
        'Day--yesterday': yesterday,
        'Day--locked': locked && after,
        'Day--missing': missing,
        'Day--done': done,
      })}
    >
      {format(date, 'd')}
    </button>
  );
});

export function WeeklyHeader({
  withSunday = true,
  includingWeekNo = true,
}: {
  withSunday?: boolean;
  includingWeekNo?: boolean;
}): ReactElement {
  const { formatMessage } = useIntl();
  const days = eachDayOfInterval({
    start: getStartOfWeek(new Date()),
    end: getEndOfWeek(new Date()),
  });
  return (
    <div className="Weekly Weekly--header">
      {includingWeekNo && (
        <span className="Weekly-index">
          {formatMessage({ id: 'components.weekly.header.week.label' })}
        </span>
      )}
      {days
        .filter((day) => day.getDay() !== 0 || withSunday)
        .map((day) => {
          const formatted = formatDay(day);
          return (
            <div className="Day" key={formatted}>
              {formatted}
            </div>
          );
        })}
    </div>
  );
}

export interface WeeklyParams {
  weeklyDate: Date;
  mode?: 'locked' | 'missing' | 'done';
  includingWeekNo?: boolean;
  withSunday?: boolean;
  itemRenderer?: (selected: Date) => ReactNode;
}

export const defaultDayRenderer = (
  dailyStore: DailyStore,
  date: Date,
): FunctionComponent<DayProps> => {
  return observer(
    (dayProps: DayProps): ReactElement => {
      const props: DayProps = {
        ...dayProps,
        date,
      };
      const formatted = formatDate(date);
      const dailyLog = dailyStore.list[formatted];
      const mode = resolveDailyMode(dailyLog);
      const exists = dailyLog && !dailyLog.done && dailyLog.locked;
      if (exists) {
        props.onSelect = null;
      }

      const element = <Day key={date.getDay()} {...props} mode={mode} />;

      return exists ? (
        <Popover
          content={
            <p>
              {formatMessage({
                id: 'components.weekly.day.missing_locked',
              })}
            </p>
          }
          position={Position.TOP}
          usePortal={false}
        >
          {element}
        </Popover>
      ) : (
        element
      );
    },
  );
};

export const Weekly = observer(function Weekly(params: WeeklyParams) {
  const { dailyStore } = useStores();
  const {
    weeklyDate,
    mode,
    itemRenderer = (date: Date): ReactNode => {
      const Element = defaultDayRenderer(dailyStore, date);
      return <Element date={date} key={formatDate(date)} />;
    },
    includingWeekNo = true,
    withSunday = true,
  } = params;
  const startDate = getStartOfWeek(weeklyDate);
  const endDate = getEndOfWeek(weeklyDate);
  const weekNo = getWeekNumber(weeklyDate);
  const days = eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).filter((day) => day.getDay() !== 0 || withSunday);

  return (
    <div
      key={weekNo}
      className={classNames('Weekly', {
        'Weekly--locked': mode === 'locked',
        'Weekly--missing': mode === 'missing',
        'Weekly--done': mode === 'done',
      })}
    >
      {includingWeekNo && <span className="Weekly-index">{weekNo}</span>}
      {days.map(itemRenderer)}
    </div>
  );
});

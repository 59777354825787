import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { formatDate, NAMED_MONTH_PATTERN } from '../../lib/date.utils';
import { useStores } from '../../stores';
import { Button } from '../Button';
import { generateRoute } from '../../routes';

import './index.scss';

export interface MiniEvaluationProps {
  className?: string;
}

export const MissingBoard = observer(function MissingBoard(
  props: MiniEvaluationProps,
): ReactElement {
  const { className = '' } = props;
  const { dailyStore } = useStores();
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  if (dailyStore.firstMissing === undefined) {
    return <MissingBoardEmpty />;
  }

  return (
    <div className={classNames('MissingBoard', className)}>
      <div className="MissingBoard-top">
        <div className="MissingBoard-top-handler MissingBoard-top-handler--left" />
        <div className="MissingBoard-top-handler MissingBoard-top-handler--right" />
      </div>
      <div className="MissingBoard-content">
        <h3 className="MissingBoard-content-title">
          {formatMessage({
            id: 'components.missing_board.title.label',
          })}
        </h3>
        <div className="MissingBoard-content-date">
          {!!dailyStore.firstMissing &&
            formatDate(dailyStore.firstMissing, NAMED_MONTH_PATTERN)}
        </div>
        <Button
          className="MissingBoard-content-button"
          intent={'danger'}
          large
          onClick={(event) => {
            event.preventDefault();
            if (dailyStore.firstMissing) {
              push(
                generateRoute('studentSubmitForm', {
                  date: formatDate(dailyStore.firstMissing),
                }),
              );
            }
          }}
        >
          {formatMessage({
            id: 'components.missing_board.add_now.label',
          })}
        </Button>
      </div>
    </div>
  );
});

function MissingBoardEmpty(): ReactElement {
  const { formatMessage } = useIntl();
  return (
    <div className="MissingBoard MissingBoard--success">
      <h1 className="MissingBoard-title">
        {formatMessage({ id: 'components.missing_board.success.label' })}
      </h1>
      <p className="MissingBoard-desc">
        {formatMessage({ id: 'components.missing_board.success.desc' })}
      </p>
    </div>
  );
}

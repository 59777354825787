import { observable, action, computed, reaction } from 'mobx';
import { ContentGroup, getContentGroups } from '../lib/client';
import { AbstractStore } from './abstract.store';
import { RootStore } from './root.store';

class GroupsStore extends AbstractStore {
  @observable groups: ContentGroup[] = [];
  @observable loading = false;

  constructor(
    rootStore: RootStore,
    private type: 'year' | 'level' | 'profession' | 'group',
  ) {
    super(rootStore);
  }

  public init() {
    this.disposers.push(
      reaction(
        () => [this.root.authStore.isLoggedIn],
        () => {
          this.load();
        },
      ),
    );
  }

  @action async load(): Promise<void> {
    if (!this.root.authStore.isLoggedIn) {
      return;
    }
    this.startLoading();
    getContentGroups(`${this.root.authStore.token}`, this.type)
      .then(({ rows }) => {
        this.setGroups(rows);
        this.stopLoading();
      })
      .catch((e) => {
        console.error(e);
        this.setGroups([]);
        this.stopLoading();
      });
  }

  @action setGroups(groups: ContentGroup[]): void {
    this.groups = groups;
  }

  @action startLoading(): void {
    this.loading = true;
  }

  @action stopLoading(): void {
    this.loading = false;
  }

  @computed get formatted() {
    return this.groups.map(({ id, name, children }) => ({
      id,
      name: name.match(/\w+-\w+ (.+)/)?.[1] || name,
      children,
    }));
  }
}

export { GroupsStore };

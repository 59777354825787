import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { useStores } from '../../stores';
import { OnlyAuthorized } from '../OnlyAuthorized';
import { MissingBoard } from '../../components/MissingBoard';
import { Loader } from '../../components/Loader';
import { StudentCalendar } from '../../components/StudentCalendar';

import './index.scss';

export const StudentDetailsContainer = observer(
  function StudentDetailsContainer(): ReactElement | null {
    const { studentStore, authStore } = useStores();
    const studentId = authStore.user?.id;

    useEffect(() => {
      if (studentId) {
        studentStore.setId(studentId);
      }
    }, [studentId, studentStore]);

    if (!studentId || !studentStore.current) {
      return null;
    }

    return (
      <>
        <Loader loading={studentStore.isLoading}>
          <OnlyAuthorized>
            <div className={classNames('StudentDetailsContainer')}>
              <Header />
              <StudentCalendar />
            </div>
          </OnlyAuthorized>
        </Loader>
      </>
    );
  },
);

function Header(): ReactElement {
  const {
    studentStore: { isLoading },
  } = useStores();

  return (
    <Loader loading={isLoading}>
      <header className="row Header">
        <div className="col">
          <MissingBoard />
        </div>
      </header>
    </Loader>
  );
}

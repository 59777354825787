import { rootStore } from '../stores';
import { formatDate } from './date.utils';
import { toaster } from './toaster';
import { formatMessage } from '../translations';
import { debounce } from './utils';

let controller: AbortController | undefined = undefined;
let config: ClientConfiguration | undefined = undefined;

type ClientConfiguration = Map<'token' | 'baseUrl', string | undefined>;

export interface Request {
  uri: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: any;
  headers?: Headers | string[][] | Record<string, string> | undefined;
}

function getBaseUrl(): string {
  if (process.env.REACT_APP_BASE_URL) {
    return `${process.env.REACT_APP_BASE_URL}`;
  }

  return window.origin;
}

function reinitController(): AbortController {
  controller = new AbortController();
  return controller;
}

const cancel = (): void => {
  try {
    controller?.abort();
    reinitController();
  } catch (err) {
    console.error(err);
  }
};

const configure = (newConfig: ClientConfiguration): void => {
  for (const [key, value] of Array.from(newConfig.entries())) {
    config?.set(key, value);
  }
};

const clear = (): void => {
  config?.delete('token');
};

const init = (): void => {
  config = new Map();
  controller = reinitController();
  config.set('baseUrl', getBaseUrl());
  Object.assign(window, {
    config,
  });
};

const DEFAULT_HEADERS = { 'content-type': 'application/json' };

const showExpiredSessionError = debounce(function showExpiredSessionError() {
  toaster.show({
    intent: 'danger',
    message: formatMessage({
      id: 'common.session.expired',
    }),
  });
}, 500);

const json = async <Response>({
  uri,
  method = 'GET',
  body = undefined,
  headers = DEFAULT_HEADERS,
}: Request): Promise<Response | undefined> => {
  try {
    const response = await request({ uri, method, body, headers });

    if (response.status === 401) {
      showExpiredSessionError();
      rootStore.authStore.clear();
    }

    if (response.status > 400) {
      const error = new Error(`Invalid Response`);
      Object.assign(error, { response });
      throw error;
    }

    try {
      return response.json();
    } catch (e) {
      return undefined;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const blob = async ({
  uri,
  method = 'GET',
  body = undefined,
  headers = DEFAULT_HEADERS,
}: Request): Promise<Blob | undefined> => {
  try {
    const response = await request({ uri, method, body, headers });
    if (response.status === 401) {
      showExpiredSessionError();
      rootStore.authStore.logout();
    }

    if (response.status > 400) {
      const error = new Error(`Invalid Response`);
      Object.assign(error, { response });
      throw error;
    }

    try {
      return response.blob();
    } catch (e) {}
  } catch (e) {
    console.error(e);
  }
};

function getSignal(): AbortSignal {
  if (controller?.signal?.aborted || controller?.signal === undefined) {
    return reinitController().signal;
  }

  return controller?.signal;
}

const request = async ({
  uri,
  method = 'GET',
  body = undefined,
  headers = DEFAULT_HEADERS,
}: Request): Promise<Response> => {
  try {
    const options = {
      signal: getSignal(),
      method,
      body,
      headers,
    };

    return fetch(`${config?.get('baseUrl')}${uri}`, options);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

async function authorizeViaMagicLink(
  token: string,
  email?: string,
): Promise<string | undefined> {
  let err: Error;

  if (!token.length) {
    throw new Error('Magic Link token cannot be empty');
  }

  try {
    const response = await json<{ access_token: string }>({
      uri: `/api/auth/magic-link`,
      method: 'POST',
      body: JSON.stringify({ token, email }),
    });

    if (response === undefined) {
      err = new Error('Magic link authentication failed. Invalid Token.');
    } else {
      const { access_token } = response;
      return access_token;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function authorizeViaCredentials(
  username: string,
  password: string,
): Promise<string | undefined> {
  let err: Error;
  try {
    const response = await json<{ access_token: string }>({
      uri: `/api/auth/login`,
      method: 'POST',
      body: JSON.stringify({ username, password }),
    });

    if (response === undefined) {
      err = new Error('Invalid Credentials.');
    } else {
      const { access_token = undefined } = response;
      return access_token;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

export interface Student {
  id: number;
  username: string;
  email?: string;
  avatar?: string | null;
  year?: string|string[];
  grade_level?: string|string[];
  profession?: string|string[];
  group?: string|string[];
  missing_logs: number;
  missing_evaluations: number;
  evaluation: number;
  social_skills_evaluation: number;
  rules_evaluation: number;
  knowledge_evaluation: number;
}

export interface ContentGroup {
  id: number;
  name: string;
  children?: ContentGroup[];
}

export interface StudentsCriteria {
  limit: number;
  offset: number;
  startDate?: string;
  endDate?: string;
  professions?: number[];
  levels?: number[];
  groups?: number[];
  years?: number[];
  orderBy?: { column: string; dir: 'asc' | 'desc' }[];
}

async function getStudents(
  token: string,
  criteria: StudentsCriteria,
): Promise<{ rows: Student[]; total: number }> {
  let err: Error;
  try {
    const response = await json<{ rows: Student[]; total: number }>({
      uri: `/api/students?${fromCriteria(criteria).toString()}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response === undefined) {
      err = new Error('Magic link authentication failed. Invalid Token.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

function fromCriteria({
  limit = 10,
  offset = 0,
  groups = [],
  levels = [],
  years = [],
  professions = [],
  startDate = undefined,
  endDate = undefined,
  orderBy = [],
}: StudentsCriteria): URLSearchParams {
  const params = new URLSearchParams();
  params.set('limit', `${limit}`);
  params.set('offset', `${offset}`);

  if (startDate !== undefined) {
    params.set('sd', startDate);
  }
  if (endDate !== undefined) {
    params.set('ed', endDate);
  }

  groups.forEach((group) => params.append('g[]', `${group}`));
  years.forEach((year) => params.append('y[]', `${year}`));
  professions.forEach((profession) => params.append('p[]', `${profession}`));
  levels.forEach((level) => params.append('l[]', `${level}`));
  orderBy.forEach((order) =>
    params.append(`o[${order.column}]`, `${order.dir}`),
  );

  return params;
}

async function getStudent(id: number): Promise<Student | undefined> {
  let err: Error;
  try {
    const response = await json<Student | undefined>({
      uri: `/api/students/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('IUser not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function getContentGroups(
  token: string,
  type: string,
): Promise<{ rows: ContentGroup[] }> {
  let err: Error;
  try {
    const response = await json<{ rows: ContentGroup[]; total: number }>({
      uri: `/api/groups/${type}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response === undefined) {
      err = new Error('Magic link authentication failed. Invalid Token.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

export interface Activity {
  id: number;
  name: string;
  type: ActivityType;
}

export enum ActivityType {
  SPECIAL_FIELD = 1,
  IFA = 2,
  CAMPUS = 3,
  FREE = 4,
  SCHOOL = 5,
  TRAINING = 6,
  VACATION = 7,
  SICKNESS = 8,
  PUBLIC_HOLIDAY = 9,
}

async function getActivities(): Promise<{ rows: Activity[]; total: number }> {
  let err: Error;
  try {
    const response = await json<{ rows: Activity[]; total: number }>({
      uri: `/api/activities`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Activities cannot be loaded.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

export interface IUser {
  username: string;
  id: number;
  email?: string;
  avatar?: string;
  roles: {
    id: number;
    name: string;
  }[];
  isAdmin: boolean;
  isStudent: boolean;
  isTrainer: boolean;
}

async function getUser(): Promise<IUser | undefined> {
  let err: Error;
  try {
    const response = await json<IUser | undefined>({
      uri: `/api/auth/me`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('IUser not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

export enum WeeklyType {
  REGULAR = 1,
  IGNORED = 2,
}

export interface IWeekly {
  id: number;
  date: string;
  week: number;
  studentId: number;
  teacherId: number;
  featured: boolean;
  evaluation?: number;
  socialSkillsEvaluation?: number;
  knowledgeEvaluation?: number;
  rulesEvaluation?: number;
  comment?: string;
  locked: boolean;
  hidden: boolean;
  type: WeeklyType;
}

export interface IWeeklyRequest {
  id?: number;
  date: string;
  studentId: number;
  teacherId: number;
  evaluation: number;
  comment: string;
}

export interface IDailyLog extends Partial<IDaily> {
  date: string;
  locked: boolean;
  done: boolean;
  required: boolean;
  missing: boolean;
  expired: boolean;
  author: IUser;
}

export interface IDaily {
  id: number;
  studentId: number;
  startedAt: string;
  finishedAt: string;
  breakStartedAt: string;
  breakFinishedAt: string;
  comment: string;
  activity: {
    id: number;
    name: string;
    type: number;
  };
  createdBy: number;
  unlockedAt: string | null | undefined;
}

export interface IDailyRequest {
  date: string;
  studentId: number;
  startedAt: string;
  finishedAt: string;
  breakStartedAt: string;
  breakFinishedAt: string;
  comment: string;
  activityType: number;
}

export interface ILockingRequest {
  action: 'lock' | 'unlock';
  studentId: number;
  date: string;
}

async function getWeeklyLogs({
  startDate,
  endDate,
  studentId,
}: {
  startDate: string;
  endDate: string;
  studentId: number;
}): Promise<{ rows: IWeekly[]; total: number }> {
  let err: Error;
  const params = new URLSearchParams();
  params.set('sd', startDate);
  params.set('ed', endDate);
  params.set('sid', `${studentId}`);
  try {
    const response = await json<{ rows: IWeekly[]; total: number }>({
      uri: `/api/weekly?${params.toString()}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('IWeekly results not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function getWeeklyLog({ date }: { date: string }): Promise<IWeekly> {
  let err: Error;
  try {
    const response = await json<IWeekly>({
      uri: `/api/weekly/dates/${date}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('IWeekly results not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function createOrUpdateWeekly(weekly: IWeeklyRequest): Promise<IWeekly> {
  let err: Error;
  try {
    const response = await json<IWeekly>({
      uri: `/api/weekly/create-or-update`,
      method: 'POST',
      body: JSON.stringify(weekly),
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Sth went wrong. Please try again later.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function removeWeekly(id: number): Promise<IWeekly> {
  let err: Error;
  try {
    const response = await json<IWeekly>({
      uri: `/api/weekly/${id}`,
      method: 'DELETE',
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Sth went wrong. Please try again later.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function getDailyLogs({
  startDate,
  endDate,
  studentId,
}: {
  startDate: string;
  endDate: string;
  studentId: number;
}): Promise<Record<string, IDailyLog>> {
  let err: Error;
  const params = new URLSearchParams();
  params.set('sd', startDate);
  params.set('ed', endDate);
  params.set('sid', `${studentId}`);
  try {
    const response = await json<Record<string, IDailyLog>>({
      uri: `/api/daily/list-missing?${params.toString()}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('IDailyLog results not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function getDailyLog({
  date,
}: {
  date: string;
}): Promise<IDailyLog | undefined> {
  let err: Error;
  try {
    const response = await json<IDailyLog | undefined>({
      uri: `/api/daily/dates/${date}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('IDailyLog results not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function removeDaily(id: number): Promise<IDailyLog> {
  let err: Error;
  try {
    const response = await json<IDailyLog>({
      uri: `/api/daily/${id}`,
      method: 'DELETE',
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Sth went wrong. Please try again later.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function getFirstMissingDailyLog({
  date,
  studentId,
}: {
  date: string;
  studentId: number;
}): Promise<IDailyLog | undefined> {
  let err: Error;
  try {
    const response = await json<IDailyLog | undefined>({
      uri: `/api/daily/first-missing?d=${date}&sid=${studentId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Last missing daily log not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function getSettings(studentId: number): Promise<Record<string, any>> {
  let err: Error;
  try {
    const response = await json<Record<string, any>>({
      uri: `/api/settings?sid=${studentId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Last missing daily log not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function getManualPoints(
  studentId: number,
): Promise<DetailedManualPoint[]> {
  let err: Error;
  try {
    const response = await json<DetailedManualPoint[]>({
      uri: `/api/students/${studentId}/score-logs`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Last missing daily log not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function changePoints(
  studentId: number,
  manualPointChange: ManualPoint,
): Promise<DetailedManualPoint[]> {
  let err: Error;
  try {
    const response = await json<DetailedManualPoint[]>({
      uri: `/api/students/${studentId}/score-logs`,
      method: 'POST',
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
      body: JSON.stringify(manualPointChange),
    });

    if (response === undefined) {
      err = new Error('Last missing daily log not found');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function exportPoints(criteria: {
  studentIds: number[];
  startDate: Date;
  endDate: Date;
}): Promise<Blob> {
  let err: Error;
  try {
    const response = await blob({
      uri: `/api/students/export-points`,
      method: 'POST',
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
      body: JSON.stringify({
        ...criteria,
        startDate: formatDate(criteria.startDate),
        endDate: formatDate(criteria.endDate),
      }),
    });

    if (response === undefined) {
      err = new Error('Sth went wrong. Please try again.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}
async function exportEntries(criteria: {
  studentIds: number[];
  startDate: Date;
  endDate: Date;
}): Promise<Blob> {
  let err: Error;
  try {
    const response = await blob({
      uri: `/api/daily/export`,
      method: 'POST',
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
      body: JSON.stringify({
        ...criteria,
        startDate: formatDate(criteria.startDate),
        endDate: formatDate(criteria.endDate),
      }),
    });

    if (response === undefined) {
      err = new Error('Sth went wrong. Please try again.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function createOrUpdateDaily(daily: IDailyRequest): Promise<IDailyLog> {
  let err: Error;
  try {
    const response = await json<IDailyLog>({
      uri: `/api/daily/create-or-update`,
      method: 'POST',
      body: JSON.stringify(daily),
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Sth went wrong. Please try again later.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

async function lockDailyLogs(req: ILockingRequest): Promise<IDailyLog[]> {
  let err: Error;
  try {
    const response = await json<IDailyLog[]>({
      uri: `/api/daily/locking`,
      method: 'POST',
      body: JSON.stringify(req),
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Sth went wrong. Please try again later.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

export interface BatchRequest {
  students: number[];
  dates: string[];
  activity: ActivityType;
  disabledWeeks: string[];
}

export interface BatchResponse {
  success: boolean;
}

async function applyBatchDailyLogs(data: BatchRequest): Promise<boolean> {
  let err: Error;
  try {
    const response = await json<BatchResponse | undefined>({
      uri: `/api/daily/batch`,
      method: 'POST',
      body: JSON.stringify({
        ...data,
        activity: Number(data.activity),
      }),
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    const success = response?.success || false;

    if (!success) {
      err = new Error('An error occured. Please try again.');
    } else {
      return success;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}

export interface ManualPoint {
  name: string;
  scoreChange: number;
}

export interface DetailedManualPoint extends ManualPoint {
  id: number;
  studentId: number;
  creationDate: string;
  createdBy?: string;
}


async function exportWeeklyEvaluations(): Promise<Blob> {
  let err: Error;
  try {
    const response = await blob({
      uri: `/api/weekly/export`,
      method: 'POST',
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${config?.get('token')}`,
      },
    });

    if (response === undefined) {
      err = new Error('Sth went wrong. Please try again.');
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
    err = e;
  }

  throw err;
}


export {
  clear,
  configure,
  init,
  json,
  cancel,
  getSettings,
  authorizeViaMagicLink,
  authorizeViaCredentials,
  createOrUpdateWeekly,
  getWeeklyLogs,
  getWeeklyLog,
  removeWeekly,
  lockDailyLogs,
  getDailyLogs,
  getDailyLog,
  getFirstMissingDailyLog,
  createOrUpdateDaily,
  applyBatchDailyLogs,
  removeDaily,
  getUser,
  getStudents,
  getStudent,
  getContentGroups,
  getActivities,
  getManualPoints,
  changePoints,
  exportPoints,
  exportEntries,
  exportWeeklyEvaluations,
};

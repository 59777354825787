import React from 'react';
import { Logout } from '../../containers/Logout';
import { OnlyAuthorized } from '../../containers/OnlyAuthorized';
import { OnlyGuest } from '../../containers/OnlyGuest';
import { generateRoute } from '../../routes';

const DummyLogging = () => {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  return (
    <div
      className="row"
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '10px',
        position: 'absolute',
        left: '10px',
        right: '10px',
        bottom: '10px',
        translate: '(-50%, -50%)',
        zIndex: 1000,
        overflow: 'hidden',
        margin: 0,
      }}
    >
      <OnlyGuest>
        <a
          className="Button Button--large"
          href={generateRoute('studentDetails', {
            'play-token': '123',
            email: 'test+a1trainer1@ovos.at',
          })}
        >
          <b>trainer [test+a1trainer1@ovos.at]</b>
        </a>
        <a
          className="Button Button--large"
          href={generateRoute('studentDetails', {
            'play-token': '123',
            email: 'test+a1learner4@ovos.at',
          })}
        >
          <b>elektronik account [test+a1learner4@ovos.at]</b>
        </a>
        <a
          className="Button Button--large"
          href={generateRoute('studentDetails', {
            'play-token': '123',
            email: 'test+a1learner1@ovos.at',
          })}
        >
          <b>retail-account [test+a1learner1@ovos.at]</b>
        </a>
        <a
          className="Button Button--large"
          href={generateRoute('studentDetails', {
            'play-token': '123',
            email: 'mkr@ovos.at',
          })}
        >
          <b>admin [mkr@ovos.at]</b>
        </a>
        <a
          className="Button Button--large"
          href={generateRoute('studentDetails', {
            'play-token': '123',
            email: 'test+a1learner6@ovos.at',
          })}
        >
          <b>test+a1learner6@ovos.at</b>
        </a>
        <a
          className="Button Button--large"
          href={generateRoute('studentDetails', {
            'play-token': '123',
            email: 'test+a1learner7@ovos.at',
          })}
        >
          <b>test+a1learner7@ovos.at</b>
        </a>
        <a
          className="Button Button--large"
          href={generateRoute('studentDetails', {
            'play-token': '123',
            email: 'test+a1learner8@ovos.at',
          })}
        >
          <b>test+a1learner8@ovos.at</b>
        </a>
      </OnlyGuest>
      <OnlyAuthorized>
        <Logout />
      </OnlyAuthorized>
    </div>
  );
};

const Fake = () => null;

export default process.env.NODE_ENV !== 'development' ? Fake : DummyLogging;

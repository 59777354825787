import React, { ReactElement, ReactNode, useState, ChangeEvent } from 'react';
import { DatePicker } from '@blueprintjs/datetime';
import { observer } from 'mobx-react-lite';
import { addDays, isValid, subDays } from 'date-fns';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { useStores } from '../../stores';
import { StudentsList } from '../../components/StudentsList';
import { OnlyAuthorized } from '../OnlyAuthorized';
import { ROLE_ADMIN, ROLE_TRAINER } from '../../stores/auth.store';
import { Input } from '../../components/Input';
import { Checkbox } from '../../components/Checkbox';
import { Icon } from '../../components/Icon';
import { formatDate } from '../../lib/date.utils';
import { withoutEmpty } from '../../lib/utils';
import { Unauthorized } from '../Unauthorized';
import { Button } from '../../components/Button';
import { Loader } from '../../components/Loader';

import './index.scss';

const MultiSelect = <Type extends { name: string; id: number }>({
  items,
  label,
  placeholder,
  active,
  onOpen,
  onSelect,
  onDeselect,
  selectedItems = [],
}: {
  label: ReactNode;
  placeholder: ReactNode;
  active: boolean;
  items: Type[];
  selectedItems: Type[];
  onOpen: (open: boolean) => void;
  onSelect: (groups: Type[]) => void;
  onDeselect: (groups: Type[]) => void;
}): ReactElement => {
  const [selected, setSelected] = useState<Type[]>(selectedItems);

  function handleRemoveByName(name: string) {
    const group = selected.find((element) => element.name === name);
    if (group) {
      setSelected(selected.filter((element) => element.name !== name));
      onDeselect([group]);
    }
  }

  function handleOnSelect(group: Type) {
    setSelected([...selected, group]);
    onSelect([group]);
  }

  return (
    <div
      className={classNames('Select', {
        'Select--open': active,
      })}
    >
      <div className={'Select-label'}>{label}</div>
      <div className={'Select-placeholder'}>
        <div onClick={() => onOpen(!active)}>
          <strong>{placeholder}</strong>
          <i className="Select-indicator a1-icon" />
        </div>
        <div className={'Select-options'}>
          {items.map((item) => (
            <div className={'Select-option'} key={item.id}>
              <Checkbox
                className={'bigger'}
                checked={!!selected.find((selected) => selected.id === item.id)}
                onChange={(checked) => {
                  if (checked) {
                    handleOnSelect(item);
                  } else {
                    handleRemoveByName(item.name);
                  }
                }}
              >
                {item.name}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const FilterableDate = (props: {
  label: ReactNode;
  placeholder: ReactNode;
  active: boolean;
  onChange: (date: Date) => void;
  onOpen: (open: boolean) => void;
  value?: Date;
  min?: Date;
  max?: Date;
  className?: string;
}): ReactElement => {
  const {
    label,
    placeholder,
    value,
    onChange,
    active,
    onOpen,
    min,
    max,
    className = '',
  } = props;

  return (
    <div
      className={classNames('Select', 'Select-date', className, {
        'Select--open': active,
      })}
    >
      <div className={'Select-label'}>{label}</div>
      <div className={'Select-placeholder'}>
        <div onClick={() => onOpen(!active)}>
          <strong>{placeholder}</strong>
          <i className="Select-indicator a1-icon" />
        </div>
        <div className={'Select-options'}>
          <div className={'Select-option'}>
            <DatePicker
              defaultValue={value ?? undefined}
              onChange={(selectedDate) => {
                onChange(selectedDate);
              }}
              minDate={min}
              maxDate={max}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type ActiveFilter =
  | undefined
  | 'groups'
  | 'years'
  | 'professions'
  | 'levels'
  | 'startDate'
  | 'endDate'
  | 'onlyWith';

const StudentsFilterForm = observer(
  (): ReactElement => {
    const {
      yearsStore,
      professionsStore,
      groupsStore,
      levelsStore,
      studentsStore,
    } = useStores();
    const { formatMessage } = useIntl();
    const [active, setActive] = useState<ActiveFilter>(undefined);

    function createOpeningCallback(
      filter: ActiveFilter,
    ): (open: boolean) => void {
      return (open) => {
        setActive(open ? filter : undefined);
      };
    }

    const missingLogsItems = [
      {
        id: 1,
        name: formatMessage({
          id: 'containers.students.filters.only_missing_logs.label',
        }),
      },
      {
        id: 2,
        name: formatMessage({
          id: 'containers.students.filters.only_missing_evaluations.label',
        }),
      },
    ];
    return (
      <header>
        <div className="row">
          <div className="col">
            <div className="StudentsFilterForm">
              <MultiSelect
                label={
                  <>{formatMessage({ id: 'containers.students.year.label' })}</>
                }
                placeholder={
                  <>
                    {studentsStore.criteria.years.length
                      ? studentsStore.criteria.years
                          .map((group) => group.name)
                          .join(', ')
                      : formatMessage({
                          id: 'containers.students.filters.all.label',
                        })}
                  </>
                }
                active={active === 'years'}
                onOpen={createOpeningCallback('years')}
                key={'years'}
                items={yearsStore.formatted}
                onSelect={(groups) =>
                  studentsStore.selectGroups('years', ...groups)
                }
                onDeselect={(groups) =>
                  studentsStore.deselectGroups('years', ...groups)
                }
                selectedItems={studentsStore.criteria.years || []}
              />
              <MultiSelect
                label={formatMessage({
                  id: 'containers.students.profession.label',
                })}
                placeholder={
                  <>
                    {studentsStore.criteria.professions.length
                      ? studentsStore.criteria.professions
                          .map((group) => group.name)
                          .join(', ')
                      : formatMessage({
                          id: 'containers.students.filters.all.label',
                        })}
                  </>
                }
                key={'professions'}
                active={active === 'professions'}
                onOpen={createOpeningCallback('professions')}
                items={professionsStore.formatted}
                onSelect={(groups) =>
                  studentsStore.selectGroups('professions', ...groups)
                }
                onDeselect={(groups) =>
                  studentsStore.deselectGroups('professions', ...groups)
                }
                selectedItems={studentsStore.criteria.professions || []}
              />
              <MultiSelect
                label={formatMessage({
                  id: 'containers.students.grade_level.label',
                })}
                placeholder={
                  <>
                    {studentsStore.criteria.levels.length
                      ? studentsStore.criteria.levels
                          .map((group) => group.name)
                          .join(', ')
                      : formatMessage({
                          id: 'containers.students.filters.all.label',
                        })}
                  </>
                }
                key={'levels'}
                active={active === 'levels'}
                onOpen={createOpeningCallback('levels')}
                items={levelsStore.formatted}
                onSelect={(groups) =>
                  studentsStore.selectGroups('levels', ...groups)
                }
                onDeselect={(groups) =>
                  studentsStore.deselectGroups('levels', ...groups)
                }
                selectedItems={studentsStore.criteria.levels || []}
              />
              <MultiSelect
                label={formatMessage({ id: 'containers.students.group.label' })}
                placeholder={
                  <>
                    {studentsStore.criteria.groups.length
                      ? studentsStore.criteria.groups
                          .map((group) => group.name)
                          .join(', ')
                      : formatMessage({
                          id: 'containers.students.filters.all.label',
                        })}
                  </>
                }
                key={'groups'}
                active={active === 'groups'}
                onOpen={createOpeningCallback('groups')}
                items={groupsStore.formatted}
                onSelect={(groups) =>
                  studentsStore.selectGroups('groups', ...groups)
                }
                onDeselect={(groups) =>
                  studentsStore.deselectGroups('groups', ...groups)
                }
                selectedItems={studentsStore.criteria.groups || []}
              />
              <FilterableDate
                key={'start-date'}
                className={`Select-date-from`}
                label={formatMessage({
                  id: 'containers.students.period.label',
                })}
                placeholder={formatDate(studentsStore.criteria.startDate)}
                value={studentsStore.criteria.startDate}
                active={active === 'startDate'}
                max={subDays(studentsStore.criteria.endDate, 1)}
                onOpen={createOpeningCallback('startDate')}
                onChange={(date) => {
                  if (date) {
                    studentsStore.setDates(
                      date,
                      studentsStore.criteria.endDate,
                    );
                  }
                }}
              />
              <FilterableDate
                key={'end-date'}
                label=""
                placeholder={
                  isValid(studentsStore.criteria.endDate)
                    ? formatDate(studentsStore.criteria.endDate)
                    : ''
                }
                value={studentsStore.criteria.endDate}
                active={active === 'endDate'}
                min={addDays(studentsStore.criteria.startDate, 1)}
                onOpen={createOpeningCallback('endDate')}
                onChange={(date) => {
                  if (date) {
                    studentsStore.setDates(
                      studentsStore.criteria.startDate,
                      date,
                    );
                  }
                }}
              />
              <MultiSelect
                label={formatMessage({ id: 'containers.students.group.label' })}
                placeholder={
                  <>
                    {formatMessage({
                      id: 'containers.students.filters.evaluation.label',
                    })}
                  </>
                }
                key={'onlyWith'}
                active={active === 'onlyWith'}
                onOpen={createOpeningCallback('onlyWith')}
                items={missingLogsItems}
                onSelect={([evaluation]) => {
                  switch (evaluation.id) {
                    case 1:
                      studentsStore.onlyWithMissingLogs(true);
                      break;
                    case 2:
                      studentsStore.onlyWithMissingEvaluations(true);
                      break;
                  }
                }}
                onDeselect={([evaluation]) => {
                  switch (evaluation.id) {
                    case 1:
                      studentsStore.onlyWithMissingLogs(false);
                      break;
                    case 2:
                      studentsStore.onlyWithMissingEvaluations(false);
                      break;
                  }
                }}
                selectedItems={[
                  missingLogsItems.find(
                    (item) =>
                      item.id === 1 && studentsStore.criteria.onlyMissingLogs,
                  ),
                  missingLogsItems.find(
                    (item) =>
                      item.id === 2 &&
                      studentsStore.criteria.onlyMissingEvaluations,
                  ),
                ].filter(withoutEmpty)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <OnlyAuthorized withRoles={[ROLE_ADMIN, ROLE_TRAINER]}>
              <div className="Students-search">
                <Input
                  label={'Search query'}
                  inputProps={{
                    defaultValue: studentsStore.criteria.query,
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      studentsStore.setQuery(event.target.value);
                    },
                    type: 'search',
                    placeholder: 'Name oder Email', // TODO
                  }}
                />
                <Icon name="search" title="Type to search" />
                {/* TODO */}
              </div>
            </OnlyAuthorized>
          </div>
          <div className="col-12 col-md-8 d-flex justify-content-end align-content-center">
            <OnlyAuthorized withRoles={[ROLE_ADMIN]}>
              <>
                <Button
                  outlined
                  intent="primary"
                  disabled={studentsStore.loading}
                  onClick={() => studentsStore.exportPoints()}
                >
                  {formatMessage({
                    id: 'containers.students.exports.manual_points',
                  })}
                </Button>
                <Button
                  outlined
                  intent="primary"
                  disabled={studentsStore.loading}
                  onClick={() => studentsStore.exportEntries()}
                >
                  {formatMessage({ id: 'containers.students.exports.daily' })}
                </Button>
                <Button
                  outlined
                  intent="secondary"
                  disabled={studentsStore.loading}
                  onClick={() => studentsStore.exportWeeklyEvaluations()}
                >
                  {formatMessage({ id: 'containers.students.exports.weekly' })}
                </Button>
              </>
            </OnlyAuthorized>
          </div>
        </div>
      </header>
    );
  },
);

export const Students = observer(function Students(): ReactElement {
  const { studentsStore } = useStores();

  return (
    <>
      <StudentsFilterForm />
      <OnlyAuthorized
        withRoles={[ROLE_TRAINER, ROLE_ADMIN]}
        authorizedOnly={true}
        fallback={<Unauthorized />}
      >
        <article className="row">
          <Loader loading={studentsStore.loading} className="col-12">
            <div className="row">
              <StudentsList
                className="Students-table col-12"
                loading={studentsStore.loading}
                onSortingChange={(sorting): void => {
                  studentsStore.setOrdering(
                    Array.from(sorting.entries()).map(([column, dir]) => ({
                      column,
                      dir,
                    })),
                  );
                }}
                students={studentsStore.currentPageStudents}
                selectedStudents={studentsStore.selectedStudents}
                onSelectStudent={(s) => studentsStore.selectStudents(s)}
                onDeselectStudent={(s) => studentsStore.deselectStudents(s)}
                total={studentsStore.total}
                hasNext={studentsStore.hasNext}
                hasPrev={studentsStore.hasPrev}
                onNext={() => studentsStore.nextPage()}
                onPrev={() => studentsStore.prevPage()}
              />
            </div>
          </Loader>
        </article>
      </OnlyAuthorized>
    </>
  );
});

import React, { ReactElement, useState } from 'react';
import { Card, Elevation } from '@blueprintjs/core';
import { Redirect, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SignInForm } from '../../components/SignInForm';
import { authorizeViaCredentials } from '../../lib/client';
import { useStores } from '../../stores';
import Loader from '../../components/Loader';
import { generateRoute } from '../../routes';
import { toaster } from '../../lib/toaster';
import { formatMessage } from '../../translations';

export const AdminLogin = observer(function AdminLogin(): ReactElement {
  const [loading, setLoading] = useState<boolean>(false);
  const { authStore } = useStores();
  const { push } = useHistory();

  if (authStore.isLoggedIn) {
    return <Redirect to={generateRoute('adminStudents')} />;
  }

  return (
    <Loader loading={loading}>
      <div className="row">
        <Card
          interactive={true}
          elevation={Elevation.TWO}
          className="col-4 offset-4"
        >
          <SignInForm
            onSignIn={({ username, password }) => {
              setLoading(true);
              authorizeViaCredentials(username, password)
                .then((response) => {
                  if (response) {
                    authStore
                      .authorize(response)
                      .then((success) => {
                        if (success) {
                          push(generateRoute('adminStudents'));
                        }
                      })
                      .catch((err) => console.error(err));
                  } else {
                    toaster.show({
                      intent: 'danger',
                      message: formatMessage({
                        id: 'common.session.error',
                      }),
                    });
                  }
                })
                .catch(() => {
                  toaster.show({
                    intent: 'danger',
                    message: formatMessage({
                      id: 'common.session.error',
                    }),
                  });
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          />
        </Card>
      </div>
    </Loader>
  );
});

import React, { ReactElement, ReactNode } from 'react';

import './index.scss';

export type BadgeIntent = 'default' | 'danger' | 'primary';

export interface BadgeProps {
  children: ReactNode;
  intent?: BadgeIntent;
}

export function Badge(
  props: BadgeProps & React.HTMLAttributes<HTMLSpanElement>,
): ReactElement {
  const { children, intent = 'default', className = '', ...others } = props;
  return (
    <span className={`Badge Badge--${intent} ${className}`} {...others}>
      {children}
    </span>
  );
}

import React, { ReactElement, SyntheticEvent } from 'react';
import { Classes, Drawer, Position } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';

import { useStores } from '../../stores';
import { Activity } from '../../lib/client';
import { Button } from '../Button';
import { Calendar } from '../Calendar';
import { toaster } from '../../lib/toaster';
import { Checkbox } from '../Checkbox';

import './index.scss';

type BatchActionsData = {
  activity: number;
  days: string[];
  disableEvaluations: boolean;
};

export const BatchActionsForm = observer(
  function BatchActionsForm(): ReactElement {
    const { studentsStore, studentStore, dailyStore } = useStores();
    const { formatMessage } = useIntl();
    const { register, handleSubmit, watch } = useForm<BatchActionsData>();

    const onSubmit: SubmitHandler<BatchActionsData> = ({ activity }) => {
      if (
        !window.confirm(formatMessage({ id: 'common.actions.confirm.label' }))
      ) {
        return;
      }
      studentsStore
        .applyBatch({
          students: studentsStore.selectedStudentsIds,
          dates: studentsStore.selectedDatesList,
          disabledWeeks: studentsStore.disabledWeeks,
          activity,
        })
        .then((success) => {
          if (success) {
            studentsStore.clearSelection();
            toaster.show({
              intent: 'success',
              message: formatMessage({
                id: 'components.batch_action.toaster.success',
              }),
            });
          } else {
            toaster.show({
              intent: 'warning',
              message: formatMessage({
                id: 'components.batch_action.toaster.error',
              }),
            });
          }
        })
        .catch((err) => console.error(err));
    };

    const onClose = (event?: Event | SyntheticEvent): void => {
      if (event) event.preventDefault();
      studentsStore.clearSelection();
    };

    watch('activity');

    return (
      <Drawer
        isOpen={studentsStore.isSelected}
        onClose={onClose}
        usePortal={true}
        hasBackdrop={false}
        position={Position.RIGHT}
        size={'390px'}
        title={
          <span className="BatchActionsForm-title">
            {studentsStore.selectedStudentsIds.length}{' '}
            {formatMessage({ id: 'components.batch_action.title.label' })}
          </span>
        }
        isCloseButtonShown={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={false}
        className={classNames(
          'BatchActionsForm container-fluid',
          Classes.OVERLAY_SCROLL_CONTAINER,
        )}
        portalClassName={'BatchActionsForm-portal'}
      >
        <div className="row justify-content-center align-content-center">
          <div className="col-12">
            <form
              className="BatchActionsForm-form row"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="BatchActionsForm-calendar col-12">
                <Calendar
                  withSunday={false}
                  defaultValue={studentStore.criteria.selectedDate}
                  selectedDates={studentsStore.selectedDatesList}
                  onSelect={(selected) => studentsStore.selectDates(selected)}
                  doneDates={
                    studentsStore.selectedStudents.size === 1
                      ? dailyStore.listDoneDates
                      : []
                  }
                  missingDates={
                    studentsStore.selectedStudents.size === 1
                      ? dailyStore.listMissingDates
                      : []
                  }
                  onNext={() => studentStore.nextMonth()}
                  onPrev={() => studentStore.prevMonth()}
                />
              </div>
              <div className="BatchActionsForm-activities col-12">
                <div className="row">
                  {studentsStore.canWeeklyEvaluationBeDisabled && (
                    <div className="col-12">
                      <Checkbox
                        checked={studentsStore.allowDisablingEvaluations}
                        onChange={(checked) =>
                          studentsStore.setDisablingEvaluations(checked)
                        }
                        className={'bigger'}
                      >
                        {formatMessage({
                          id:
                            'components.batch_action.disableEvaluations.label',
                        })}
                      </Checkbox>
                    </div>
                  )}

                  <div className="col-12">
                    <h4>
                      {formatMessage({
                        id: 'components.batch_action.activities.label',
                      })}
                    </h4>
                  </div>
                  <div className="col-12">
                    <select
                      className={
                        ' Button Button--default Button--outlined Button--large Button--fill'
                      }
                      ref={register({ required: true })}
                      name="activity"
                    >
                      {dailyStore.activities.map((activity: Activity) => (
                        <option value={activity.type} key={activity.type}>
                          {activity.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="BatchActionsForm-buttons col-12">
                <Button type="submit" intent="danger" large fill>
                  {formatMessage({ id: 'components.batch_action.form.submit' })}
                </Button>
                <Button
                  type="reset"
                  intent="default"
                  large
                  fill
                  onClick={onClose}
                >
                  {formatMessage({ id: 'components.batch_action.form.reset' })}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    );
  },
);

import React, { ReactElement, ReactNode, useEffect } from 'react';
import { History } from 'history';
import { useHistory } from 'react-router-dom';

import { authorizeViaMagicLink } from '../../lib/client';
import { useStores } from '../../stores';
import { toaster } from '../../lib/toaster';
import { formatMessage } from '../../translations';
import { debounce } from '../../lib/utils';

const showMagicLinkError = debounce(function showMagicLinkError() {
  toaster.show({
    intent: 'danger',
    message: formatMessage({
      id: 'common.session.error',
    }),
  });
}, 500);

export function MagicLinkHandler({
  browserHistory,
  children = null,
}: {
  browserHistory: History<History.PoorMansUnknown>;
  children?: ReactNode | undefined | null;
}): ReactElement {
  const { authStore } = useStores();
  const { push: appPush } = useHistory();
  const {
    push,
    location: { search, pathname },
  } = browserHistory;

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('play-token')) {
      const playToken = queryParams.get('play-token') as string;
      const email = queryParams.get('email') || undefined;
      authorizeViaMagicLink(playToken, email)
        .then((token) => {
          if (typeof token === 'string') {
            authStore
              .authorize(token)
              .then((success) => {
                if (!success) {
                  authStore.clear();
                  showMagicLinkError();
                }
              })
              .catch(() => {
                authStore.clear();
                showMagicLinkError();
              });
          } else {
            authStore.clear();
            showMagicLinkError();
          }
          push({ pathname });
        })
        .catch((err) => {
          console.error(err);
          authStore.clear();
          showMagicLinkError();
          push({ pathname });
        });
    }
  }, [pathname, search, push, appPush, authStore]);
  return <>{children}</>;
}

import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import './index.scss';

export function Time(props: {
  inputProps: React.HTMLProps<HTMLInputElement>;
  labelProps?: React.HTMLProps<HTMLLabelElement>;
  label?: ReactNode;
  className?: string;
}): ReactElement {
  const { inputProps, labelProps, label = null, className = '' } = props;
  const { className: labelClassName, ...labelInputProps } = labelProps || {
    className: '',
  };
  const {
    className: inputClassName,
    onChange,
    ...passedInputProps
  } = inputProps;

  return (
    <div className={classNames('Input', className)}>
      <label
        {...labelInputProps}
        className={classNames('Input-label', labelClassName)}
      >
        {label}
      </label>
      <div className="Input-field">
        {/*{time || inputProps.placeholder || '--:--'}*/}
        <input
          {...passedInputProps}
          type={'time'}
          className={inputClassName}
          onChange={(event) => {
            event.preventDefault();
            if (onChange) {
              onChange(event);
            }
          }}
        />
      </div>
    </div>
  );
}

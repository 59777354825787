import React, {
  ReactElement,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../stores';
import Loader from '../../components/Loader';
import LoadingDelay from '../../components/LoadinDelay';

function AppLoaderComp({ children }: PropsWithChildren<unknown>): ReactElement {
  const { authStore } = useStores();
  const [shown, setShown] = useState<boolean>(false);

  useEffect(() => {
    const tt = setTimeout(() => {
      setShown(true);
    }, 100);

    return () => {
      clearTimeout(tt);
    };
  }, []);

  return (
    <div className="row">
      <LoadingDelay check={authStore.isAuthenticating} delay={500}>
        {({ isLoading, isDelaying }) => (
          <Loader
            loading={isDelaying || isLoading || !shown}
            className={'col-12'}
          >
            {children}
          </Loader>
        )}
      </LoadingDelay>
    </div>
  );
}

export const AppLoader = observer(AppLoaderComp);

import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { Icon } from '../Icon';

import './index.scss';

export interface EvaluationBarProps {
  defaultValue?: number;
  className?: string;
  locked?: boolean;
  featured?: boolean;
  editable?: boolean;
  slim?: boolean;
  onChange?: (value: number) => void;
}

export function EvaluationBar(props: EvaluationBarProps): ReactElement {
  const {
    defaultValue,
    onChange,
    locked = false,
    featured = false,
    editable = false,
    slim = false,
    className = '',
  } = props;

  const { formatMessage } = useIntl();
  const [sliderValue, setSliderValue] = useState<number>(defaultValue || 0);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    setSliderValue(defaultValue || 0);
  }, [defaultValue]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!editable) {
      return;
    }
    const val = Number(e.currentTarget.value);
    setSliderValue(val);
    if (onChange) onChange(val);
  };

  const isDone = sliderValue ? sliderValue > 95 : false;
  const offset = sliderValue > 50 ? '0px' : '13px';

  const missing = sliderValue === 0 && !focused && editable;
  const valueExists = sliderValue > 0;

  const isRangeSelectorShowable = editable && focused;
  const isProgressBarShowable = valueExists && !missing;
  const isLabelShowable = missing && !locked && !valueExists;

  const onFocus = () => {
    if (!editable) return;
    setFocused(true);
  };

  const onBlur = () => {
    if (!editable) return;
    setFocused(false);
  };

  return (
    <div
      onClick={onFocus}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onFocus}
      onMouseLeave={onBlur}
      className={classNames('EvaluationBar', className, {
        'EvaluationBar--slim': slim,
        'EvaluationBar--locked': locked,
        'EvaluationBar--done': isDone,
        'EvaluationBar--focused': focused,
        'EvaluationBar--missing': missing,
      })}
    >
      {isProgressBarShowable && (
        <span
          className={'EvaluationBar-slider'}
          style={{
            width: `calc(${sliderValue}% + ${offset} )`,
          }}
        />
      )}
      {isRangeSelectorShowable && (
        <>
          <input
            type="range"
            min="0"
            max="100"
            value={sliderValue}
            className="EvaluationBar-range"
            onChange={handleOnChange}
          />
          <span
            className={'EvaluationBar-button'}
            style={{ width: `calc(${sliderValue} - 4px )` }}
          />
        </>
      )}
      <div className={'EvaluationBar-text'}>
        {featured && !slim && (
          <Icon name="star" className="EvaluationBar-icon" />
        )}
        {locked && !slim && <Icon name="lock" className="EvaluationBar-icon" />}
        {!isLabelShowable && !slim && `${sliderValue.toFixed()}%`}
        {isLabelShowable &&
          formatMessage({ id: 'components.evaluation_bar.evaluate.label' })}
      </div>
    </div>
  );
}

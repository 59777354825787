import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';

import { AppLoader } from '../../components/AppLoader';

export function UnauthorizedComponent(): ReactElement {
  return (
    <AppLoader>
      <div className="row">
        <div className="col">
          <h1>Unauthorized</h1>
          <p>You&apos;re not authorized to see the page.</p>
        </div>
      </div>
    </AppLoader>
  );
}

export const Unauthorized = observer(UnauthorizedComponent);

import React, {
  ReactElement,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from 'react';
import classNames from 'classnames';

import './index.scss';

interface CheckboxProps {
  checked: boolean;
  children?: ReactNode;
  onChange?: (checked: boolean) => void;
  className?: string;
  checkboxProps?: InputHTMLAttributes<HTMLInputElement>;
}

export function Checkbox({
  checked,
  children = null,
  onChange = undefined,
  className = '',
  checkboxProps = {},
}: CheckboxProps): ReactElement {
  const [id] = useState<string>(
    checkboxProps?.id || Math.random().toString(36).substr(2, 9),
  );
  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...checkboxProps,
    checked,
    id,
    type: 'checkbox',
    value: 'checked',
    onChange: (event) => {
      const {
        currentTarget: { checked },
      } = event;
      if (onChange) {
        onChange(checked);
      }
    },
  };
  return (
    <div
      className={classNames('Checkbox', className, {
        'Checkbox--checked': checked,
      })}
    >
      <input {...inputProps} />
      <label htmlFor={id}>{children}</label>
    </div>
  );
}

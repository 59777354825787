import { IDailyLog } from '../../lib/client';
import { DayMode } from './index';

export function resolveDailyMode(dailyLog?: IDailyLog): DayMode | undefined {
  if (dailyLog === undefined) {
    return undefined;
  }
  if (dailyLog.missing && dailyLog.required) {
    return 'missing';
  }
  if (dailyLog.done) {
    return 'done';
  }
  if (dailyLog.locked) {
    return 'locked';
  }
  return undefined;
}

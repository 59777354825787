import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../Icon';

import './index.scss';

interface NavbarParams {
  children: ReactNode;
  url: string;
}

export function Navbar({ children, url }: NavbarParams): ReactElement {
  return (
    <div className={'Navbar'}>
      <Link to={url} className={'Navbar-back'}>
        <Icon name={'chevron-left'} />
      </Link>
      <div className={'Navbar-container'}>{children}</div>
    </div>
  );
}

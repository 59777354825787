import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import './index.scss';

// export function Input(props: React.HTMLProps<HTMLInputElement>): ReactElement {
//   const { className, ...passedProps } = props;
//   return <input {...passedProps} className={classNames('Input', className)} />;
// }

export function Input(props: {
  inputProps: React.HTMLProps<HTMLInputElement>;
  labelProps?: React.HTMLProps<HTMLLabelElement>;
  label?: ReactNode;
  className?: string;
}): ReactElement {
  const { inputProps, labelProps, label = null, className = '' } = props;
  const { className: labelClassName, ...labelInputProps } = labelProps || {
    className: '',
  };
  const { className: inputClassName, ...passedInputProps } = inputProps;
  return (
    <div className={classNames('Input', className)}>
      <label
        {...labelInputProps}
        className={classNames('Input-label', labelClassName)}
      >
        {label}
      </label>
      <input
        {...passedInputProps}
        className={classNames('Input-field', inputClassName)}
      />
    </div>
  );
}

import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import './index.scss';

export function Textarea(props: {
  textareaProps: React.HTMLProps<HTMLTextAreaElement>;
  labelProps?: React.HTMLProps<HTMLLabelElement>;
  label?: ReactNode;
}): ReactElement {
  const { textareaProps, labelProps, label = null } = props;
  const { className: labelClassName, ...labelTextareaProps } = labelProps || {
    className: '',
  };
  const {
    className: textareaClassName,
    ...passedTextareaProps
  } = textareaProps;
  return (
    <div className="Textarea">
      <label
        {...labelTextareaProps}
        className={classNames('Textarea-label', labelClassName)}
      >
        {label}
      </label>
      <textarea
        {...passedTextareaProps}
        className={classNames('Textarea-field', textareaClassName)}
      />
    </div>
  );
}

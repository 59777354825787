import {
  action,
  autorun,
  computed,
  IObservableArray,
  observable,
  ObservableMap,
  reaction,
  transaction,
} from 'mobx';
import { endOfDay, isWeekend, startOfDay, subMonths } from 'date-fns';

import {
  ActivityType,
  applyBatchDailyLogs,
  ContentGroup,
  getStudents,
  exportPoints,
  exportEntries,
  Student,
  exportWeeklyEvaluations,
} from '../lib/client';
import { AbstractStore } from './abstract.store';
import { formatDate, getWeekNumber } from '../lib/date.utils';
import { exportFile } from '../lib/utils';

interface Criteria {
  query: string | undefined;
  limit: number;
  offset: number;
  groups: IObservableArray<ContentGroup>;
  years: IObservableArray<ContentGroup>;
  levels: IObservableArray<ContentGroup>;
  professions: IObservableArray<ContentGroup>;
  startDate: Date;
  endDate: Date;
  onlyMissingLogs: boolean;
  onlyMissingEvaluations: boolean;
  orderBy: IObservableArray<{ column: string; dir: 'asc' | 'desc' }>;
}

class StudentsStore extends AbstractStore {
  private timeout: number | undefined;

  @observable students: IObservableArray<Student> = observable.array([]);
  @observable loading = false;
  @observable total = 0;
  // limit used for view
  @observable limit = 10;
  // offset used for view
  @observable offset = 0;
  @observable.deep criteria: Criteria = {
    limit: 20,
    offset: 0,
    orderBy: observable.array([]),
    groups: observable.array([]),
    levels: observable.array([]),
    years: observable.array([]),
    professions: observable.array([]),
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
    onlyMissingLogs: false,
    onlyMissingEvaluations: false,
    query: undefined,
  };

  @observable selectedStudents: ObservableMap<
    number,
    Student
  > = observable.map();

  @observable selectedDates: ObservableMap<string, Date> = observable.map();
  @observable allowDisablingEvaluations = false;

  public init() {
    this.disposers.push(
      reaction(
        () => [
          this.root.authStore.isLoggedIn,
          this.criteria.startDate,
          this.criteria.endDate,
          // this.criteria.orderBy,
        ],
        () => {
          if (this.root.authStore.isLoggedIn) {
            this.clear();
            this.load({ limit: this.criteria.limit, offset: 0 });
          }
        },
        {
          name: 'students store - reload students after criteria change',
        },
      ),
      autorun(
        () => {
          if (this.selectedStudents.size === 1) {
            const id = [...this.selectedStudentsIds].shift() || 0;
            if (this.root.studentStore) {
              this.root.studentStore.setId(id);
            }
          } else {
            if (this.root.studentStore) {
              this.root.studentStore.setId(undefined);
            }
            this.root.dailyStore.clear();
            this.root.weeklyStore.clear();
          }
        },
        {
          name: 'students store - reload students after criteria change',
        },
      ),
    );
  }

  @computed get filteredStudents(): Student[] {
    if (this.loading) {
      return [];
    }

    if (!this.students) {
      return [];
    }

    return [...this.students].filter((student: Student) => {
      if (
        this.criteria.years?.length &&
        !this.criteria.years
          ?.map((group) => group.name)
          .filter((name) => student?.year?.includes(name)).length
      ) {
        return false;
      }
      if (
        this.criteria.groups?.length &&
        !this.criteria.groups
          ?.map((group) => group.name)
          .filter((name) => student?.group?.includes(name)).length
      ) {
        return false;
      }
      if (
        this.criteria.professions?.length &&
        !this.criteria.professions
          ?.map((group) => group.name)
          .filter((name) => student?.profession?.includes(name)).length
      ) {
        return false;
      }
      if (
        this.criteria.levels?.length &&
        !this.criteria.levels
          ?.map((group) => group.name)
          .filter((name) => student?.grade_level?.includes(name)).length
      ) {
        return false;
      }
      if (
        this.criteria.query?.length &&
        !`${Object.values(student).join(' ').toLowerCase()}`.includes(
          this.criteria.query.toLowerCase(),
        )
      ) {
        return false;
      }
      if (this.criteria.onlyMissingLogs && !student.missing_logs) {
        return false;
      }

      return !(
        this.criteria.onlyMissingEvaluations && !student.missing_evaluations
      );
    });
  }

  @computed get sortedStudents(): Student[] {
    const students = [...(this.filteredStudents || [])];
    return students.sort((a: Student, b: Student) => {
      for (const orderBy of this.criteria.orderBy.toJS()) {
        const { dir, column } = orderBy;
        // @ts-ignore
        if (a[column] > b[column]) return dir === 'asc' ? 1 : -1;
        // @ts-ignore
        if (a[column] < b[column]) return dir === 'asc' ? -1 : 1;
      }

      return 0;
    });
  }

  @computed get currentPageStudents() {
    const { limit, offset } = this;
    const start = offset - 1 < 0 ? 0 : offset;
    const end = start + limit;
    return [...this.sortedStudents].slice(start, end);
  }

  @action async load({
    limit,
    offset,
  }: {
    limit: number;
    offset: number;
  }): Promise<void> {
    if (!this.criteria.startDate || !this.criteria.endDate) {
      return;
    }
    this.startLoading();
    getStudents(`${this.root.authStore.token}`, {
      limit,
      offset,
      // orderBy: this.criteria.orderBy,
      // professions: this.criteria.professions?.map((group) => group.id) || [],
      // levels: this.criteria.levels?.map((group) => group.id) || [],
      // years: this.criteria.years?.map((group) => group.id) || [],
      // groups: this.criteria.groups?.map((group) => group.id) || [],
      startDate: formatDate(this.criteria.startDate),
      endDate: formatDate(this.criteria.endDate),
    })
      .then(({ rows, total }) => {
        const loadMore = total > limit + offset;
        this.addStudents(rows);
        this.setTotal(total);

        if (loadMore) {
          return this.load({ limit, offset: offset + limit });
        } else {
          this.stopLoading();
        }
      })
      .catch((e) => {
        console.error(e);
        this.setStudents([], 0);
      });
  }

  @action setStudents(students: Student[], total: number): void {
    this.students.clear();
    this.students.push(...students);
    this.total = total;
  }

  @action addStudents(students: Student[]): void {
    this.students.push(...students);
  }

  @action clearOffset() {
    this.offset = 0;
  }

  @action selectGroups(
    type: 'professions' | 'years' | 'levels' | 'groups',
    ...groups: ContentGroup[]
  ) {
    this.startLoading();
    const mergeGroups = [...(this.criteria[type] || []), ...groups];

    Object.assign(this.criteria, {
      [type]: mergeGroups.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos;
      }),
    });

    this.clearOffset();
    this.stopLoading();
  }

  @action deselectGroups(
    type: 'professions' | 'years' | 'levels' | 'groups',
    ...groups: ContentGroup[]
  ) {
    this.startLoading();
    const groupIds = groups.map((group) => group.id);
    const mergeGroups = [...(this.criteria[type] || [])].filter(
      (group) => !groupIds.includes(group.id),
    );

    Object.assign(this.criteria, {
      [type]: mergeGroups.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos;
      }),
    });

    this.clearOffset();
    this.stopLoading();
  }

  @action clearGroups() {
    this.startLoading();
    this.criteria.groups.clear();
    this.criteria.levels.clear();
    this.criteria.professions.clear();
    this.criteria.years.clear();
    this.clearOffset();
    this.stopLoading();
  }

  @action nextPage(): void {
    this.startLoading();
    this.offset = this.offset + this.limit;
    this.stopLoading();
  }

  @action prevPage(): void {
    this.startLoading();
    Object.assign(this, {
      offset: this.offset <= this.limit ? 0 : this.offset - this.limit,
    });
    setTimeout(() => {
      this.stopLoading();
    }, 250);
  }

  @action startLoading(): void {
    this.loading = true;
  }

  @action stopLoading(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(
      action(() => {
        this.loading = false;
      }),
      50,
    );
  }

  @computed get hasNext(): boolean {
    return this.filteredStudents.length > this.limit + this.offset;
  }

  @computed get hasPrev(): boolean {
    return !!this.offset;
  }

  @action setDates(startDate: Date, endDate: Date) {
    this.criteria.startDate = startDate;
    this.criteria.endDate = endDate;
  }

  @action setOrdering(ordering: { column: string; dir: 'asc' | 'desc' }[]) {
    this.criteria.orderBy.clear();
    this.criteria.orderBy.push(...ordering);
    this.offset = 0;
  }

  @action setTotal(total: number) {
    this.total = total;
  }

  @action clear() {
    this.students.clear();
  }

  @action setQuery(query: string): void {
    this.startLoading();
    this.clearOffset();
    if (query !== undefined && query.length) {
      this.criteria.query = query;
    } else {
      this.criteria.query = undefined;
    }
    this.stopLoading();
  }

  getById(id: number): Student | undefined {
    return [...this.students].find((s) => s.id === Number(id));
  }

  @action reload(): void {
    this.clear();
    this.load({ limit: this.criteria.limit, offset: 0 });
  }

  @computed get prevStudentIdx(): number | undefined {
    return (
      this.sortedStudents.findIndex(
        (student) => student.id === this.root.studentStore.current?.id,
      ) - 1
    );
  }

  @computed get nextStudentIdx(): number | undefined {
    return (
      this.sortedStudents.findIndex(
        (student) => student.id === this.root.studentStore.current?.id,
      ) + 1
    );
  }

  @computed get hasNextStudent(): boolean {
    return (
      typeof this.nextStudentIdx === 'number' &&
      !!this.sortedStudents[this.nextStudentIdx]
    );
  }

  @computed get hasPrevStudent(): boolean {
    return (
      typeof this.prevStudentIdx === 'number' &&
      !!this.sortedStudents[this.prevStudentIdx]
    );
  }

  @action goToPrevStudent(): void {
    const currentIndex = [...this.sortedStudents].findIndex(
      (student) => student.id === this.root.studentStore.current?.id,
    );
    this.root.studentStore.current =
      this.sortedStudents[currentIndex - 1] || undefined;
  }

  @action goToNextStudent(): void {
    const currentIndex = [...this.sortedStudents].findIndex(
      (student) => student.id === this.root.studentStore.current?.id,
    );
    this.root.studentStore.current =
      this.sortedStudents[currentIndex + 1] || undefined;
  }

  @computed get prevStudent(): Student | undefined {
    if (this.prevStudentIdx === undefined) {
      return undefined;
    }

    return this.sortedStudents[this.prevStudentIdx];
  }

  @computed get nextStudent(): Student | undefined {
    if (this.nextStudentIdx === undefined) {
      return undefined;
    }

    return this.sortedStudents[this.nextStudentIdx];
  }

  @computed get isSelected(): boolean {
    return !!Array.from(this.selectedStudents.values()).length;
  }

  @computed get selectedDatesList(): string[] {
    return Array.from(this.selectedDates.keys()).sort();
  }

  @computed get selectedStudentsIds(): number[] {
    return Array.from(this.selectedStudents.keys());
  }

  @computed get areAllSelected(): boolean {
    return this.selectedStudentsIds.length === this.filteredStudents.length;
  }

  @computed get disabledWeeksMap(): { [key: number]: Date[] } {
    const days = Array.from(this.selectedDates.values());
    const weeks: { [key: number]: Date[] } = {};
    for (const day of days) {
      const weekNo = getWeekNumber(day);
      weeks[weekNo] = weeks[weekNo] || [];
      if (!isWeekend(day)) {
        weeks[weekNo].push(day);
      }
    }

    return weeks;
  }

  @computed get canWeeklyEvaluationBeDisabled(): boolean {
    const weeks = this.disabledWeeksMap;
    const fullWeeks = Object.values(weeks).filter((days) => days.length === 5);
    return (
      Object.keys(weeks).length === fullWeeks.length && fullWeeks.length > 0
    );
  }

  @computed get disabledWeeks(): string[] {
    if (!this.allowDisablingEvaluations) {
      return [];
    }
    const disabledWeeks: string[] = [];
    for (const week in this.disabledWeeksMap) {
      const firstDate = this.disabledWeeksMap[week].pop();
      if (firstDate) {
        const formatted = formatDate(firstDate);
        disabledWeeks.push(formatted);
      }
    }
    return disabledWeeks;
  }

  @action selectAllStudents(): void {
    this.selectStudents(...this.filteredStudents);
  }

  @action deselectAllStudents(): void {
    this.selectedStudents.clear();
  }

  @action selectStudents(...students: Student[]): void {
    transaction(() => {
      for (const student of students) {
        this.selectedStudents.set(student.id, student);
      }
    });
  }

  @action deselectStudents(...students: Student[]): void {
    transaction(() => {
      for (const student of students) {
        this.selectedStudents.delete(student.id);
      }
    });
  }

  @action selectDates(...dates: Date[]): void {
    for (const date of dates) {
      const formatted = formatDate(date);
      if (this.selectedDates.has(formatted)) {
        this.selectedDates.delete(formatted);
      } else {
        this.selectedDates.set(formatted, date);
      }
    }
  }

  @action clearSelection(): void {
    this.selectedStudents.clear();
    this.selectedDates.clear();
    this.setDisablingEvaluations(false);
  }

  @action applyBatch(data: {
    activity: ActivityType;
    students: number[];
    dates: string[];
    disabledWeeks: string[];
  }): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.startLoading();
      applyBatchDailyLogs(data)
        .then((success) => {
          resolve(success);
          this.reload();
          this.root.dailyStore.reload();
          this.root.weeklyStore.reload();
        })
        .catch((err) => {
          this.stopLoading();
          console.error(err);
          reject(err);
        });
    });
  }

  @action onlyWithMissingLogs(include: boolean): void {
    this.criteria.onlyMissingLogs = include;
  }

  @action onlyWithMissingEvaluations(include: boolean): void {
    this.criteria.onlyMissingEvaluations = include;
  }

  @action exportPoints() {
    const studentIds = this.filteredStudents.map((s) => s.id);
    this.startLoading();
    exportPoints({
      studentIds,
      startDate: startOfDay(this.criteria.startDate),
      endDate: endOfDay(this.criteria.endDate),
    })
      .then((blob) => {
        exportFile(blob, Date.now(), 'xlsx');
        this.stopLoading();
      })
      .catch((err) => console.error(err));
  }

  @action exportEntries() {
    const studentIds = this.filteredStudents.map((s) => s.id);
    this.startLoading();
    exportEntries({
      studentIds,
      startDate: this.criteria.startDate,
      endDate: this.criteria.endDate,
    })
      .then((blob) => {
        exportFile(blob, Date.now(), 'xlsx');
        this.stopLoading();
      })
      .catch((err) => console.error(err));
  }

  @action exportWeeklyEvaluations() {
    this.startLoading();
    exportWeeklyEvaluations()
      .then((blob) => {
        exportFile(blob, Date.now(), 'xlsx');
      })
      .finally(() => this.stopLoading())
      .catch((err) => console.error(err));
  }

  @action setDisablingEvaluations(disabled = false) {
    this.allowDisablingEvaluations = disabled;
  }
}

export { StudentsStore };

import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { addWeeks, endOfWeek, subWeeks } from 'date-fns';
import { useIntl } from 'react-intl';

import { generateRoute } from '../../routes';
import { useStores } from '../../stores';
import { getWeeklyLog, IWeekly } from '../../lib/client';
import { Icon } from '../../components/Icon';
import {
  DefaultDateOptions,
  formatDate,
  NAMED_WEEK_PATTERN,
  parseDate,
} from '../../lib/date.utils';
import { OnlyAuthorized } from '../OnlyAuthorized';
import { EvaluationBar } from '../../components/EvaluationBar';
import { Weekly, WeeklyHeader } from '../../components/Weekly';
import { MonthSwitcher } from '../../components/MonthSwitcher';
import Loader from '../../components/Loader';

import './index.scss';

export const WeeklyFeedbackContainer = observer(
  function WeeklyFeedbackContainer(): ReactElement | null {
    const { date } = useParams<{ date: string }>();
    const { authStore, studentStore } = useStores();
    const { push } = useHistory();
    const { formatMessage } = useIntl();
    const [weekly, setWeekly] = useState<IWeekly | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    function stopLoading(): void {
      window.setTimeout(() => {
        setLoading(false);
      }, 250);
    }

    useEffect(() => {
      if (!date) {
        return;
      }

      setLoading(true);
      getWeeklyLog({ date })
        .then((log) => {
          setWeekly(log);
          stopLoading();
        })
        .catch((err) => {
          console.error(err);
          stopLoading();
        });
    }, [date, authStore.user]);

    if (weekly === undefined) {
      return null;
    }
    const selectedDate = endOfWeek(parseDate(weekly.date), DefaultDateOptions);
    return (
      <Loader loading={loading}>
        <div className="WeeklyFeedback">
          <OnlyAuthorized>
            <div className="row">
              <div className="col-12">
                <MonthSwitcher
                  selected={selectedDate}
                  label={formatDate(selectedDate, NAMED_WEEK_PATTERN)}
                  nextDisabled={selectedDate >= new Date()}
                  onNext={() => {
                    const nextWeek = addWeeks(selectedDate, 1);
                    studentStore.includeWeek(nextWeek);
                    push(
                      generateRoute('feedback', { date: formatDate(nextWeek) }),
                    );
                  }}
                  onPrev={() => {
                    const previousWeek = subWeeks(selectedDate, 1);
                    studentStore.includeWeek(previousWeek);
                    push(
                      generateRoute('feedback', {
                        date: formatDate(previousWeek),
                      }),
                    );
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <WeeklyHeader withSunday={false} includingWeekNo={false} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Weekly
                  weeklyDate={selectedDate}
                  withSunday={false}
                  includingWeekNo={false}
                  key={weekly.date}
                />
              </div>
            </div>
            <div className="row WeeklyFeedback-content">
              <div className="col-12 WeeklyFeedback-evaluation">
                <h3>
                  <Icon name="star" />
                  {formatMessage({
                    id: 'containers.students.evaluation.label',
                  })}
                </h3>
                <EvaluationBar
                  defaultValue={weekly.evaluation}
                  featured={weekly.featured}
                  locked={false}
                  editable={false}
                />
                {weekly.evaluation && (
                  <>
                    <h4 className="WeeklyFeedback-subevaluation">
                      {formatMessage({
                        id: 'containers.students.knowledgeEvaluation.label',
                      })}
                    </h4>
                    <EvaluationBar
                      defaultValue={weekly.knowledgeEvaluation}
                      featured={weekly.featured}
                      locked={true}
                      editable={false}
                      slim={true}
                    />
                    <h4 className="WeeklyFeedback-subevaluation">
                      {formatMessage({
                        id: 'containers.students.socialSkillsEvaluation.label',
                      })}
                    </h4>
                    <EvaluationBar
                      defaultValue={weekly.socialSkillsEvaluation}
                      featured={weekly.featured}
                      locked={true}
                      editable={false}
                      slim={true}
                    />
                    <h4 className="WeeklyFeedback-subevaluation">
                      {formatMessage({
                        id: 'containers.students.rulesEvaluation.label',
                      })}
                    </h4>
                    <EvaluationBar
                      defaultValue={weekly.rulesEvaluation}
                      featured={weekly.featured}
                      locked={true}
                      editable={false}
                      slim={true}
                    />
                  </>
                )}
              </div>
              {!!weekly.comment && (
                <div className="col-12 WeeklyFeedback-evaluation">
                  <h3>
                    <Icon name="bubble-text" />
                    {formatMessage({ id: 'containers.feedback.comment.label' })}
                  </h3>
                  <div className="Textarea">
                    <div className="Textarea-field">{weekly.comment}</div>
                  </div>
                </div>
              )}
            </div>
          </OnlyAuthorized>
        </div>
      </Loader>
    );
  },
);

import { parse, format, getWeek, startOfWeek, endOfWeek } from 'date-fns';
import * as datefns from 'date-fns';
import { de } from 'date-fns/locale';

type DateOptions = {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
};

Object.assign(window, { datefns });

export const ISO8601_PATTERN = "yyyy-MM-dd'T'HH:mm:ssX";
export const NAMED_MONTH_PATTERN = 'EEEE, dd.M.yyyy';
export const NAMED_WEEK_PATTERN = "LLLL, 'KW' I";

export const DefaultDateOptions: DateOptions = {
  firstWeekContainsDate: 4,
  locale: {
    code: 'de',
  },
  weekStartsOn: 1,
};

export function parseDate(
  date: string,
  pattern?: string,
  options?: DateOptions,
): Date {
  const patternOrDefault = pattern || 'yyyy-MM-dd';
  const value = new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
  return parse(date, patternOrDefault, value, {
    locale: de,
    ...options,
  });
}

export function formatDate(
  date: Date,
  pattern?: string,
  options?: DateOptions,
): string {
  const patternOrDefault = pattern || 'yyyy-MM-dd';
  return format(date, patternOrDefault, {
    locale: de,
    ...options,
  });
}

export function parseDateTime(
  date: string,
  pattern?: string,
  options?: DateOptions,
): Date {
  const patternOrDefault = pattern || ISO8601_PATTERN;
  const value = new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
  return parse(date, patternOrDefault, value, {
    locale: de,
    ...options,
  });
}

export function formatDateTime(
  date: Date,
  pattern?: string,
  options?: DateOptions,
): string {
  const patternOrDefault = pattern || ISO8601_PATTERN;
  return format(date, patternOrDefault, {
    locale: de,
    ...options,
  });
}

export function formatDay(date: Date, pattern?: string): string {
  const patternOrDefault = pattern || 'EEEEEE';
  return format(date, patternOrDefault, {
    locale: de,
  });
}

export function formatStringableDay(date: string): string {
  const value = parseDate(date);
  return formatDate(value, 'd. MMMM yyyy');
}

export function formatYear(date: Date): string {
  return formatDate(date, 'yyyy');
}

export function formatMonthName(date: Date): string {
  return formatDate(date, 'MMMM');
}

export function getWeekNumber(date: Date): number {
  return getWeek(date, DefaultDateOptions);
}

export function getStartOfWeek(
  date: Date,
  options: DateOptions = DefaultDateOptions,
): Date {
  return startOfWeek(date, options);
}

export function getEndOfWeek(
  date: Date,
  options: DateOptions = DefaultDateOptions,
): Date {
  return endOfWeek(date, options);
}

import { PropsWithChildren, ReactElement } from "react";

export type SimpleArrayAsTextProps = PropsWithChildren<{
  separator?: ', ' | '\n' | string;
}>;
export default function SimpleArrayAsText(props: SimpleArrayAsTextProps): ReactElement {
  const { separator = ', ' } = props;
  const children = (Array.isArray(props.children) ? props.children : [props.children])
    .filter(child => typeof child === 'string' && child.trim() !== '')
  ;
  return children.join(separator) as unknown as ReactElement;
}

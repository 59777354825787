import React, { useEffect, useState } from 'react';

type LoadingDelayProps = {
  check: boolean;
  delay?: number;
  children: (props: {
    isLoading: boolean;
    isDelaying: boolean;
  }) => React.ReactElement;
};

const LoadingDelay: React.FC<LoadingDelayProps> = ({
  check,
  children,
  delay = 500,
}: LoadingDelayProps) => {
  const [isDelaying, setIsDelaying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let tt = 0;

    setIsLoading(check);
    setIsDelaying(!check);

    if (!check) {
      tt = window.setTimeout(() => {
        window.clearTimeout(tt);
        setIsDelaying(false);
        setIsLoading(false);
      }, delay);
    }

    return () => {
      setIsDelaying(false);
      setIsLoading(false);
      window.clearTimeout(tt);
    };
  }, [check, delay, children]);

  return children({ isLoading, isDelaying });
};

export default LoadingDelay;

import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { useStores } from '../../stores';
import { generateRoute } from '../../routes';
import { OnlyAuthorized } from '../OnlyAuthorized';
import { ROLE_ADMIN, ROLE_TRAINER } from '../../stores/auth.store';
import { OnlyGuest } from '../OnlyGuest';

export const Header = observer(function Header(): ReactElement | null {
  const { authStore } = useStores();
  return (
    <div className="container-fluid">
      <div className="row">
        <nav className="col-12 bp3-navbar bp3-dark">
          <div className="bp3-navbar-group bp3-align-left">
            <div className="bp3-navbar-heading">A1Lab</div>
          </div>
          <OnlyAuthorized withRoles={[ROLE_ADMIN, ROLE_TRAINER]}>
            <div className="bp3-navbar-group bp3-align-right">
              <Link
                className="bp3-button bp3-minimal"
                to={generateRoute('adminLogout')}
              >
                {authStore.user?.avatar && (
                  <img
                    src={authStore.user.avatar}
                    alt={authStore.user?.username}
                    className={'Avatar Avatar--small'}
                  />
                )}
                <span>{authStore.user?.username} </span>
                <b>Logout</b>
              </Link>
            </div>
          </OnlyAuthorized>
          <OnlyAuthorized withoutRoles={[ROLE_ADMIN, ROLE_TRAINER]}>
            <div className="bp3-navbar-group bp3-align-right">
              <Link
                className="bp3-button bp3-minimal"
                to={generateRoute('adminLogout')}
              >
                <b>Logout</b>
              </Link>
            </div>
          </OnlyAuthorized>
          <OnlyGuest>
            <div className="bp3-navbar-group bp3-align-right">
              <Link
                className="bp3-button bp3-minimal bp3-icon-people"
                to={generateRoute('adminLogin')}
              >
                <b>Sign in</b>
              </Link>
            </div>
          </OnlyGuest>
        </nav>
      </div>
    </div>
  );
});

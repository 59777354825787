import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import { Authorized, AuthorizedParams } from '../../components/Authorized';

interface OnlyAuthorizedParams extends AuthorizedParams {
  children: ReactNode | ReactElement | string | undefined;
}

export const OnlyAuthorized = observer(function OnlyAuthorized(
  params: OnlyAuthorizedParams,
): ReactElement | null {
  const { children, ...missingParams } = params;
  return (
    <Authorized {...missingParams} authorizedOnly={true}>
      {children}
    </Authorized>
  );
});

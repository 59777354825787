import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';

import { Students } from '../Students';
import { BatchActionsForm } from '../../components/BatchActionsForm';
import { OnlyAuthorized } from '../OnlyAuthorized';
import { ROLE_ADMIN, ROLE_TRAINER } from '../../stores/auth.store';
import { Unauthorized } from '../Unauthorized';

import './index.scss';

export const AdminStudents = observer(function (): ReactElement {
  return (
    <OnlyAuthorized
      withRoles={[ROLE_TRAINER, ROLE_ADMIN]}
      fallback={<Unauthorized />}
    >
      <div className="AdminStudents">
        <Students />
        <BatchActionsForm />
      </div>
    </OnlyAuthorized>
  );
});

import React, { Context } from 'react';
import { RootStore, Stores } from './root.store';

export const rootStore = new RootStore();

export const stores = rootStore.getStores();

export const StoreContext: Context<Stores> = React.createContext(stores);

export const useStores = (): Stores => {
  return React.useContext(StoreContext);
};

Object.assign(window, stores);

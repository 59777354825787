import React, { ReactElement } from 'react';
import { isBefore } from 'date-fns';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { MonthSwitcher } from '../MonthSwitcher';
import { Weekly, WeeklyHeader } from '../Weekly';
import { parseDate } from '../../lib/date.utils';
import { MiniEvaluation } from '../MiniEvaluation';
import { generateRoute } from '../../routes';
import { useStores } from '../../stores';

export const StudentCalendar = observer(function StudentCalendar({
  includesEvaluation = true,
}: {
  includesEvaluation?: boolean;
}): ReactElement {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const { weeklyStore, studentStore } = useStores();
  return (
    <>
      <div className="row align-items-end justify-content-end StudentDetails-week StudentDetails-week--odd StudentDetails-week--heading">
        <div className="col-12">
          <MonthSwitcher
            prevDisabled={isBefore(
              studentStore.criteria.startDate,
              studentStore.minDate,
            )}
            nextDisabled={
              !isBefore(studentStore.criteria.endDate, studentStore.maxDate)
            }
            selected={studentStore.criteria.selectedDate}
            onNext={() => studentStore.nextMonth()}
            onPrev={() => studentStore.prevMonth()}
          />
        </div>
        <div className="col-9">
          <WeeklyHeader withSunday={false} includingWeekNo={false} />
        </div>
        <div className="col-3 Weekly Weekly--header">
          <span className="Day">
            {formatMessage({
              id: 'containers.students.evaluation.label',
            })}
          </span>
        </div>
      </div>
      {weeklyStore.listAsArray.map((weekly, key) => {
        if (!weekly?.date) {
          return null;
        }
        return (
          <div
            key={key}
            className={classNames(
              'row align-items-center StudentDetails-week',
              {
                'StudentDetails-week--odd': key % 2 === 1,
              },
            )}
          >
            <div
              className={classNames('align-items-center', {
                'col-9': includesEvaluation,
                'col-12': !includesEvaluation,
              })}
            >
              <Weekly
                weeklyDate={parseDate(weekly.date)}
                withSunday={false}
                includingWeekNo={false}
                key={weekly.date}
              />
            </div>
            {includesEvaluation && (
              <div className="col-3 StudentDetails-evaluation">
                <MiniEvaluation
                  key={`${weekly.week}-${weekly.date}-${weekly.studentId}`}
                  value={weekly.evaluation}
                  locked={weekly.locked}
                  featured={weekly.featured}
                  onClick={() => {
                    push(generateRoute('feedback', { date: weekly.date }));
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
});

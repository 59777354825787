import { observable, action, computed } from 'mobx';
import { get, set, remove } from 'js-cookie';
import { AbstractStore } from './abstract.store';
import { configure, clear, getUser, IUser } from '../lib/client';

export const ROLE_ADMIN = 'admin';
export const ROLE_TRAINER = 'author';
export const ROLE_LEARNER = 'learner';

export const COOKIE_TOKEN_NAME = 'a1j4t3';
export const COOKIE_TTL = 3600 - 60;

class AuthStore extends AbstractStore {
  @observable token: string | undefined;
  @observable user: IUser | undefined;
  @observable authenticating = false;
  @observable initialized = false;

  @action
  public init(): void {
    setTimeout(() => {
      const token = get(COOKIE_TOKEN_NAME);
      if (!!token && !this.hasPlayToken) {
        this.authorize(token)
          .then((r) => console.log(r))
          .catch((err) => {
            this.clear();
            console.error(err);
          });
      } else {
        this.initialized = true;
      }
    });
  }

  @action async authorize(token: string): Promise<boolean> {
    this.token = token;
    this.authenticating = true;
    this.initialized = true;

    configure(new Map([['token', token]]));
    let user: IUser | undefined;
    try {
      user = await getUser();
    } catch (e) {
      console.error(e);
    }
    if (!user) {
      this.clear();
      return false;
    }
    this.setDetails(user);
    set(COOKIE_TOKEN_NAME, token, {
      sameSite: 'strict',
      expires: COOKIE_TTL,
    });

    this.authenticating = false;

    return true;
  }

  @action setDetails(user: IUser | undefined) {
    this.user = user;
  }

  @computed get isLoggedIn(): boolean {
    return !!this.token && !!this.user;
  }

  @computed get isAdmin(): boolean {
    return Boolean(this.user?.isAdmin);
  }

  @computed get isStudent(): boolean {
    return Boolean(this.user?.isStudent);
  }

  @computed get isTrainer(): boolean {
    return Boolean(this.user?.isTrainer);
  }

  @action logout() {
    this.clear();
    window.location.reload();
  }

  @action clear() {
    this.token = undefined;
    this.user = undefined;
    this.authenticating = false;
    this.initialized = true;
    remove(COOKIE_TOKEN_NAME);
    clear();
  }

  @computed get isAuthenticating(): boolean {
    return this.authenticating || !this.initialized || this.hasPlayToken;
  }

  @computed get hasPlayToken(): boolean {
    const params = new URLSearchParams(window.location.search);
    return params.has('play-token');
  }
}

export { AuthStore };

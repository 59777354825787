import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Button, FormGroup, InputGroup } from '@blueprintjs/core';
import { useIntl } from 'react-intl';

export function SignInForm({
  onSignIn,
}: {
  onSignIn: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => void;
}): ReactElement {
  const { formatMessage } = useIntl();
  const [credentials, setCredentials] = useState<{
    username: string;
    password: string;
  }>({ username: '', password: '' });

  function onChange(field: string, event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setCredentials({
      ...credentials,
      [field]: event.currentTarget.value,
    });
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSignIn({ ...credentials });
      }}
    >
      <FormGroup
        label={formatMessage({ id: 'containers.admin_login.form.username' })}
        labelFor="username"
        labelInfo="(required)"
      >
        <InputGroup
          id="username"
          placeholder={formatMessage({
            id: 'containers.admin_login.form.username',
          })}
          defaultValue={credentials.username}
          onChange={onChange.bind(null, 'username')}
          required
        />
      </FormGroup>
      <FormGroup
        label={formatMessage({ id: 'containers.admin_login.form.password' })}
        labelFor="password"
        labelInfo="(required)"
      >
        <InputGroup
          type={'password'}
          id="password"
          placeholder={formatMessage({
            id: 'containers.admin_login.form.password',
          })}
          defaultValue={credentials.password}
          onChange={onChange.bind(null, 'password')}
          required
        />
      </FormGroup>
      <FormGroup>
        <Button id="submit" intent={'primary'} type={'submit'}>
          {formatMessage({ id: 'containers.admin_login.form.submit' })}
        </Button>
      </FormGroup>
    </form>
  );
}

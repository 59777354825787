import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import { Authorized, AuthorizedParams } from '../../components/Authorized';

interface OnlyGuestParams extends AuthorizedParams {
  children: ReactNode | ReactElement | string | undefined;
}

export const OnlyGuest = observer(function OnlyGuest(
  params: OnlyGuestParams,
): ReactElement | null {
  const { children, ...missingParams } = params;
  return (
    <Authorized {...missingParams} authorizedOnly={false}>
      {children}
    </Authorized>
  );
});

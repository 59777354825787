import { createIntl, createIntlCache, MessageDescriptor } from 'react-intl';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';

import { default as de } from './de.json';

const GERMAN_LOCALE = 'de';
const ENGLISH_LOCALE = 'en';

export { GERMAN_LOCALE, de };

const cache = createIntlCache();
export const intl = createIntl(
  {
    locale: GERMAN_LOCALE,
    defaultLocale: ENGLISH_LOCALE,
    messages: { ...de },
  },
  cache,
);

export const formatMessage = (
  message: MessageDescriptor,
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
): string => intl.formatMessage(message, values);

import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Time } from '../Time';

import './index.scss';

type TimeTrackingData = {
  date: string;
  startedAt?: string;
  finishedAt?: string;
  breakStartedAt?: string;
  breakFinishedAt?: string;
};

export function TimeTrackingForm({
  log,
  onChange,
  readOnly,
}: {
  log: TimeTrackingData;
  readOnly: boolean;
  onChange: (data: TimeTrackingData) => void;
}): ReactElement {
  const [formData, setFormData] = useState<TimeTrackingData>({ ...log });
  const { formatMessage } = useIntl();

  useEffect(() => {
    setFormData(log);
  }, [log]);

  function onFieldChange(field: string, event: FormEvent<HTMLInputElement>) {
    const values = {
      ...formData,
      [field]: event.currentTarget.value,
    };
    setFormData(values);
    onChange(values);
  }

  return (
    <div className="TimeTrackingForm">
      <Time
        label={formatMessage({
          id: 'containers.student_submit_form.started_at.label',
        })}
        className={'TimeTrackingForm-left-top'}
        labelProps={{
          htmlFor: 'student_submit_form_started_at',
        }}
        inputProps={{
          readOnly,
          disabled: readOnly,
          id: 'student_submit_form_started_at',
          required: true,
          type: 'time',
          step: 60,
          defaultValue: formData.startedAt,
          onChange: onFieldChange.bind(null, 'startedAt'),
        }}
      />
      <Time
        label={formatMessage({
          id: 'containers.student_submit_form.break_started_at.label',
        })}
        className={'TimeTrackingForm-left-bottom'}
        labelProps={{
          htmlFor: 'student_submit_form_break_started_at',
        }}
        inputProps={{
          readOnly,
          disabled: readOnly,
          id: 'student_submit_form_break_started_at',
          required: true,
          type: 'time',
          step: 60,
          min: formData.startedAt,
          defaultValue: formData.breakStartedAt,
          onChange: onFieldChange.bind(null, 'breakStartedAt'),
        }}
      />
      <Time
        label={formatMessage({
          id: 'containers.student_submit_form.break_finished_at.label',
        })}
        className={'TimeTrackingForm-right-bottom'}
        inputProps={{
          readOnly,
          disabled: readOnly,
          required: true,
          type: 'time',
          step: 60,
          min: formData.breakStartedAt,
          defaultValue: formData.breakFinishedAt,
          onChange: onFieldChange.bind(null, 'breakFinishedAt'),
        }}
      />
      <Time
        label={formatMessage({
          id: 'containers.student_submit_form.finished_at.label',
        })}
        className={'TimeTrackingForm-right-top'}
        inputProps={{
          readOnly,
          disabled: readOnly,
          required: true,
          type: 'time',
          step: 60,
          min: formData.breakFinishedAt,
          defaultValue: formData.finishedAt,
          onChange: onFieldChange.bind(null, 'finishedAt'),
        }}
      />
    </div>
  );
}

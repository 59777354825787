import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

import { useStores } from '../../stores';
import { generateRoute } from '../../routes';

export function AdminLogout(): ReactElement | null {
  const { authStore } = useStores();
  if (authStore.isLoggedIn) {
    authStore.logout();
    return null;
  }
  return <Redirect to={generateRoute('adminLogin')} />;
}

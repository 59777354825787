import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

import { useStores } from '../../stores';
import { generateRoute } from '../../routes';

export function Admin(): ReactElement | null {
  const { authStore } = useStores();
  if (authStore.isLoggedIn) {
    return <Redirect to={generateRoute('adminStudents')} />;
  }
  return <Redirect to={generateRoute('adminLogin')} />;
}

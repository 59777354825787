import React, { MouseEvent, ReactElement, ReactNode, useState } from 'react';
import { Button, ControlGroup, NonIdealState } from '@blueprintjs/core';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Student } from '../../lib/client';
import { Checkbox } from '../Checkbox';
import { useStores } from '../../stores';
import { OnlyAuthorized } from '../../containers/OnlyAuthorized';
import { ROLE_ADMIN } from '../../stores/auth.store';
import { generateRoute } from '../../routes';
import { EvaluationBar } from '../EvaluationBar';
import { Badge } from '../Badge';
import SimpleArrayAsText from "../SimpleArrayAsText";

import './index.scss';

export type Header = {
  key: string;
  value: ReactNode;
  sortable?: boolean;
  className?: string;
};

export type Value = {
  key: string;
  value: { [key: string]: ReactNode };
};

export type Sorting = {
  key: string;
  dir: 'asc' | 'desc';
};

export interface StudentsListProps {
  students: Student[];
  total: number;
  onNext: () => void;
  onPrev: () => void;
  hasNext: boolean;
  hasPrev: boolean;
  selectedStudents: Map<number, Student>;
  onSelectStudent: (s: Student) => void;
  onDeselectStudent: (s: Student) => void;
  emptyMessage?: string;
  onSortingChange?: (sorting: SortingMap) => void;
  loading?: boolean;
  className?: string;
}

type SortingMap = Map<string, 'asc' | 'desc'>;

export const StudentsListHeader = observer(function StudentsHeader({
  onSortingChange,
}: {
  onSortingChange?: (sorting: SortingMap) => void;
}): ReactElement {
  const { studentsStore } = useStores();
  const { formatMessage } = useIntl();
  const [sorting, setSorting] = useState<SortingMap>(new Map());

  function handleOnSortClick(header: Header, e: MouseEvent): void {
    e.preventDefault();
    const { key } = header;
    let dir: 'asc' | 'desc';
    if (sorting.has(key)) {
      dir = sorting.get(key) === 'asc' ? 'desc' : 'asc';
    } else {
      dir = 'asc';
    }
    const newSorting = new Map([[key, dir]]);
    setSorting(newSorting);
    if (onSortingChange) {
      onSortingChange(newSorting);
    }
  }

  const headers: Header[] = [
    { value: 'Username', key: 'username', sortable: true },
    {
      value: formatMessage({ id: 'containers.students.year.label' }),
      key: 'year',
      sortable: true,
    },
    {
      value: formatMessage({
        id: 'containers.students.grade_level.label',
      }),
      key: 'grade_level',
      sortable: true,
    },
    {
      value: formatMessage({
        id: 'containers.students.profession.label',
      }),
      key: 'profession',
      sortable: true,
    },
    {
      value: formatMessage({ id: 'containers.students.group.label' }),
      key: 'group',
      sortable: true,
    },
    {
      value: formatMessage({
        id: 'containers.students.missing_logs.label',
      }),
      key: 'missing_logs',
      sortable: true,
    },
    {
      value: formatMessage({
        id: 'containers.students.evaluation.label',
      }),
      key: 'evaluation',
      sortable: true,
      className: 'StudentsList-column--wider',
    },
    {
      value: formatMessage({
        id: 'containers.students.missing_evaluations.label',
      }),
      key: 'missing_evaluations',
      sortable: true,
    },
  ];

  return (
    <div className={'StudentsList-header StudentsList-row'}>
      {/* @TODO */}
      <OnlyAuthorized withRoles={[ROLE_ADMIN]}>
        <div className="StudentsList-selector">
          <Checkbox
            className={''}
            checked={studentsStore.areAllSelected}
            onChange={(checked) => {
              if (checked) {
                studentsStore.selectAllStudents();
              } else {
                studentsStore.deselectAllStudents();
              }
            }}
          />
        </div>
      </OnlyAuthorized>
      <div className={'StudentsList-columns'}>
        {headers.map((header) => (
          <div
            className={classNames('StudentsList-column', header?.className)}
            key={header.key}
          >
            <Button
              minimal={true}
              disabled={!header.sortable}
              rightIcon={
                header.sortable && sorting.has(header.key)
                  ? sorting.get(header.key) === 'asc'
                    ? 'sort-asc'
                    : 'sort-desc'
                  : undefined
              }
              onClick={handleOnSortClick.bind(null, header)}
            >
              {header.value}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
});

export const StudentsList = observer(function (
  props: StudentsListProps,
): ReactElement {
  const { formatMessage } = useIntl();
  const {
    students,
    onNext,
    onPrev,
    hasNext,
    hasPrev,
    onSortingChange,
    selectedStudents,
    onSelectStudent,
    onDeselectStudent,
    emptyMessage = 'No results',
    loading = false,
    className = '',
  } = props;

  return (
    <div className={classNames('StudentsList', className)}>
      <StudentsListHeader onSortingChange={onSortingChange} />
      {students.map((student) => (
        <div className={'StudentsList-row'} key={student.id}>
          <OnlyAuthorized withRoles={[ROLE_ADMIN]}>
            <div className={'StudentsList-selector'}>
              <Checkbox
                checked={selectedStudents.has(student.id)}
                onChange={(checked) => {
                  if (checked) {
                    onSelectStudent(student);
                  } else {
                    onDeselectStudent(student);
                  }
                }}
              />
            </div>
          </OnlyAuthorized>
          <Link
            to={generateRoute('adminStudent', { id: `${student.id}` })}
            className={'StudentsList-columns'}
          >
            <div className={'StudentsList-column StudentsList-column--strong'}>
              {student?.avatar && (
                <img
                  src={student?.avatar}
                  alt={`${student.username}`}
                  className="Avatar Avatar--small"
                />
              )}
              {student.username}
            </div>
            <div className={'StudentsList-column'}><SimpleArrayAsText>{student.year}</SimpleArrayAsText></div>
            <div className={'StudentsList-column'}><SimpleArrayAsText>{student.grade_level}</SimpleArrayAsText></div>
            <div className={'StudentsList-column'}><SimpleArrayAsText>{student.profession}</SimpleArrayAsText></div>
            <div className={'StudentsList-column'}><SimpleArrayAsText>{student.group}</SimpleArrayAsText></div>
            <div className={'StudentsList-column'}>
              <Badge intent={'danger'}>{student.missing_logs}</Badge>
            </div>
            <div className={'StudentsList-column StudentsList-column--wider'}>
              <EvaluationBar
                className={'StudentsList-evaluation'}
                defaultValue={student.evaluation * 100}
                locked={false}
                editable={false}
                slim={true}
              />
              <div className="StudentsList-evaluationDetails">
                <div className={'StudentsList-evaluationDetailsColumn'}>
                  <span className={'StudentsList-evaluationLabel'}>
                    {formatMessage({
                      id:
                        'containers.weekly.evaluation_form.socialSkillsEvaluation.label',
                    })}
                  </span>
                  <EvaluationBar
                    defaultValue={student.social_skills_evaluation * 100}
                    featured={false}
                    locked={false}
                    slim={true}
                  />
                </div>
                <div className={'StudentsList-evaluationDetailsColumn'}>
                  <span className={'StudentsList-evaluationLabel'}>
                    {formatMessage({
                      id:
                        'containers.weekly.evaluation_form.knowledgeEvaluation.label',
                    })}
                  </span>
                  <EvaluationBar
                    defaultValue={student.knowledge_evaluation * 100}
                    featured={false}
                    locked={false}
                    slim={true}
                  />
                </div>
                <div className={'StudentsList-evaluationDetailsColumn'}>
                  <span className={'StudentsList-evaluationLabel'}>
                    {formatMessage({
                      id:
                        'containers.weekly.evaluation_form.rulesEvaluation.label',
                    })}
                  </span>
                  <EvaluationBar
                    defaultValue={student.rules_evaluation * 100}
                    featured={false}
                    locked={false}
                    slim={true}
                  />
                </div>
              </div>
            </div>
            <div className={'StudentsList-column'}>
              {!!student.missing_evaluations && (
                <Badge intent={'danger'}>{student.missing_evaluations}</Badge>
              )}
            </div>
          </Link>
        </div>
      ))}
      {!loading && students.length === 0 && (
        <div>
          <NonIdealState icon={'search'} title={emptyMessage} />
        </div>
      )}
      <footer className="row StudentsList-pagination">
        {!loading && (hasPrev || hasNext) && (
          <ControlGroup
            fill={false}
            vertical={false}
            className="col justify-content-center"
          >
            <Button
              icon="circle-arrow-left"
              disabled={!hasPrev}
              onClick={(event: MouseEvent): void => {
                event.preventDefault();
                onPrev();
              }}
            >
              Prev
            </Button>
            <Button
              rightIcon="circle-arrow-right"
              disabled={!hasNext}
              onClick={(event: MouseEvent): void => {
                event.preventDefault();
                onNext();
              }}
            >
              Next
            </Button>
          </ControlGroup>
        )}
      </footer>
    </div>
  );
});

import React, { ReactElement } from 'react';
import classNames from 'classnames';

import { Icon } from '../Icon';

import './index.scss';

export interface MiniEvaluationProps {
  value?: number;
  className?: string;
  locked?: boolean;
  featured?: boolean;
  icon?: boolean;
  showProgress?: boolean;
  onClick?: () => void;
}

export function ProgressRing({
  radius,
  stroke,
  progress,
}: {
  radius: number;
  stroke: number;
  progress: number;
}): ReactElement {
  const normalizedRadius = radius - stroke / 1.5;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  const borderStroke = stroke % 4;
  const borderCircumference = 0;

  return (
    <svg height={radius * 2} width={radius * 2} className="ProgressRing">
      <circle
        className={'ProgressRing-border'}
        strokeDasharray={borderCircumference + ' ' + borderCircumference}
        style={{ strokeDashoffset: borderCircumference }}
        strokeWidth={borderStroke}
        r={radius - borderStroke}
        cx={radius}
        cy={radius}
      />

      <circle
        className={'ProgressRing-progress'}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
}

export function MiniEvaluation(props: MiniEvaluationProps): ReactElement {
  const {
    value = 0,
    className = '',
    featured = false,
    locked = false,
    icon = true,
    showProgress = false,
    onClick = undefined,
  } = props;
  return (
    <span
      className={classNames('MiniEvaluation', className, {
        'MiniEvaluation--featured': featured,
        'MiniEvaluation--locked': locked,
      })}
      onClick={(event) => {
        event.preventDefault();
        if (onClick !== undefined) {
          onClick();
        }
      }}
    >
      <ProgressRing radius={18} stroke={6} progress={value} />
      {icon && !showProgress && (
        <Icon
          name={featured ? 'star-fill' : 'star'}
          className="MiniEvaluation-star"
        />
      )}
      {showProgress && (
        <span className="MiniEvaluation-label">
          {!!value && Number(value).toFixed()}
        </span>
      )}
    </span>
  );
}

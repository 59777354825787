import React, {
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Select } from '@blueprintjs/select';
import {
  Card,
  Elevation,
  MenuItem,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';

import { rootStore, useStores } from '../../stores';
import {
  defaultDayRenderer,
  Weekly,
  WeeklyHeader,
} from '../../components/Weekly';
import {
  formatDate,
  formatDateTime,
  formatStringableDay,
  ISO8601_PATTERN,
  parseDate,
  parseDateTime,
} from '../../lib/date.utils';
import { EvaluationBar } from '../../components/EvaluationBar';
import { Textarea } from '../../components/Textarea';
import { Button } from '../../components/Button';
import { OnlyAuthorized } from '../OnlyAuthorized';
import { ROLE_ADMIN, ROLE_TRAINER } from '../../stores/auth.store';
import {
  IDailyLog,
  IWeekly,
  lockDailyLogs,
  ManualPoint,
  WeeklyType,
} from '../../lib/client';
import MonthSwitcher from '../../components/MonthSwitcher';
import Loader from '../../components/Loader';
import { generateRoute } from '../../routes';
import { Icon } from '../../components/Icon';
import { OnlyGuest } from '../OnlyGuest';
import { Unauthorized } from '../Unauthorized';
import { Badge, BadgeIntent } from '../../components/Badge';
import { MiniEvaluation } from '../../components/MiniEvaluation';

import './index.scss';

function onRemoveDailyLog(daily: IDailyLog, confirmMessage = 'Are you sure?') {
  if (daily.id && window.confirm(confirmMessage)) {
    rootStore.dailyStore.delete(daily);
  }
}

export const AdminStudent = observer(
  function AdminStudent(): ReactElement | null {
    const { id } = useParams();
    const { studentStore, weeklyStore } = useStores();

    useEffect(() => {
      studentStore.setId(Number(id));
    }, [id, studentStore]);

    if (!id || !studentStore.current) {
      return null;
    }

    return (
      <>
        <OnlyAuthorized withRoles={[ROLE_ADMIN, ROLE_TRAINER]}>
          <Loader loading={studentStore.isLoading}>
            <div className={classNames('AdminStudent')}>
              <Header />
              <div className="row align-items-center justify-content-end AdminStudent-row AdminStudent-row--odd AdminStudent-row--heading">
                <div className="col-4">
                  <OnlyAuthorized>
                    <MonthSwitcher
                      selected={studentStore.criteria.selectedDate}
                      onNext={() => studentStore.nextMonth()}
                      onPrev={() => studentStore.prevMonth()}
                    />
                  </OnlyAuthorized>
                  <WeeklyHeader withSunday={false} />
                </div>
                <div className="col-8">
                  <h3>Evaluierung</h3>
                </div>
              </div>
              {weeklyStore.listAsArray.map((weekly, index) => (
                <WeeklyRow weekly={weekly} index={index} key={index} />
              ))}
              <OnlyAuthorized withRoles={[ROLE_ADMIN]}>
                <ManualPoints />
              </OnlyAuthorized>
            </div>
          </Loader>
        </OnlyAuthorized>
        <OnlyGuest>
          <Unauthorized />
        </OnlyGuest>
      </>
    );
  },
);

const WeeklyRow = observer(function WeeklyRow({
  weekly,
  index,
}: {
  weekly: IWeekly;
  index: number;
}): ReactElement | null {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    const onClose = () => {
      setIsOpen(false);
    };

    document.addEventListener('EvaluationPopup', onClose);
    return () => {
      document.removeEventListener('EvaluationPopup', onClose);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [weekly]);

  if (!weekly?.date) {
    return null;
  }

  return (
    <div
      key={index}
      className={classNames('AdminStudent-row', 'row align-items-center', {
        'AdminStudent-row--open': isOpen,
        'AdminStudent-row--odd': index % 2 === 1,
      })}
    >
      <WeeklyLocker weekly={weekly} />
      <div className="col-4 align-items-center ">
        <WeeklyEntries weekly={weekly} />
      </div>
      <div className={'col-8 AdminStudent-weekOverlay'}>
        <EvaluationForm
          weekly={weekly}
          isOpen={isOpen}
          onOpen={() => {
            document.dispatchEvent(new Event('EvaluationPopup'));
            setIsOpen(true);
          }}
          onClose={() => {
            document.dispatchEvent(new Event('EvaluationPopup'));
            setIsOpen(false);
          }}
        />
      </div>
      {/*<div className={'AdminStudent-week'}>*/}

      {/*</div>*/}
    </div>
  );
});

const WeeklyEntries = observer(function WeeklyEntries({
  weekly,
}: {
  weekly: IWeekly;
}) {
  const { dailyStore } = useStores();
  const { formatMessage } = useIntl();

  return (
    <Weekly
      weeklyDate={parseDate(weekly.date)}
      withSunday={false}
      key={weekly.date}
      itemRenderer={(date: Date): ReactNode => {
        const Element = defaultDayRenderer(dailyStore, date);
        const formattedDate = formatDate(date);
        const dailyLog = dailyStore.findByDate(date);
        const hasLoggedActivity = dailyLog && dailyLog.done;
        return (
          <Popover
            usePortal={true}
            position={Position.RIGHT}
            key={formattedDate}
          >
            <Element
              date={date}
              onSelect={(selected: Date): void => {
                console.log({ selected });
              }}
            />
            <Card
              interactive={true}
              elevation={Elevation.ZERO}
              className={classNames('Day-details', {
                'Day-details--no-activity': !hasLoggedActivity,
              })}
            >
              <Icon
                name="close"
                className="Day-details-close bp3-popover-dismiss"
              />
              <div className="row">
                <div className="col-6">
                  <h4 className="Day-details-date">
                    {hasLoggedActivity && formatStringableDay(formattedDate)}
                    {!hasLoggedActivity &&
                      formatMessage({
                        id: 'components.weekly.day.missing_activity.label',
                      })}
                  </h4>
                </div>
                <OnlyAuthorized withRoles={[ROLE_ADMIN]}>
                  <div className="col-6 d-flex align-items-end flex-column">
                    {dailyLog?.id && dailyLog.done && !dailyLog.expired && (
                      <Button
                        intent={'danger'}
                        onClick={() =>
                          onRemoveDailyLog(
                            dailyLog,
                            formatMessage({
                              id: 'common.actions.confirm.label',
                            }),
                          )
                        }
                      >
                        {formatMessage({
                          id: 'components.weekly.day.delete.label',
                        })}
                      </Button>
                    )}
                  </div>
                </OnlyAuthorized>
              </div>
              {hasLoggedActivity && (
                <>
                  <div className="row">
                    <div className="col-4">
                      <h3 className="Day-details-header">
                        {formatMessage({
                          id: 'components.weekly.day.activity.label',
                        })}
                      </h3>
                      <Button intent={'danger'} fill outlined large>
                        {dailyLog?.activity?.name}
                      </Button>
                    </div>
                    <div className="col-8">
                      <h3 className="Day-details-header">
                        {formatMessage({
                          id: 'components.weekly.day.comment.label',
                        })}
                      </h3>
                      <div className="Day-details-comment">
                        {dailyLog?.comment}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12  Day-details-hours">
                      <p>
                        <span>
                          {formatMessage({
                            id:
                              'containers.student_submit_form.started_at.label',
                          })}
                          :&nbsp;
                          <b>{dailyLog?.startedAt?.slice(0, 5)}</b>
                        </span>
                        <span>
                          {formatMessage({
                            id:
                              'containers.student_submit_form.finished_at.label',
                          })}
                          :&nbsp;
                          <b>{dailyLog?.finishedAt?.slice(0, 5)}</b>
                        </span>
                        <span>
                          {formatMessage({
                            id:
                              'containers.student_submit_form.break_started_at.label',
                          })}
                          :&nbsp;
                          <b>{dailyLog?.breakStartedAt?.slice(0, 5)}</b>
                        </span>
                        <span>
                          {formatMessage({
                            id:
                              'containers.student_submit_form.break_finished_at.label',
                          })}
                          :&nbsp;
                          <b>{dailyLog?.breakFinishedAt?.slice(0, 5)}</b>
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </Card>
          </Popover>
        );
      }}
    />
  );
});

function Header(): ReactElement {
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const {
    studentStore: { isLoading },
  } = useStores();

  function onBack(event: MouseEvent): void {
    event.preventDefault();
    push(generateRoute('adminStudents'));
  }

  return (
    <Loader loading={isLoading}>
      <header className="row AdminStudent-header">
        <div className="col-5">
          <Button onClick={onBack} className="AdminStudent-header-back">
            <Icon name="chevron-left" className="AdminStudent-header-icon" />
            {formatMessage({ id: 'common.actions.back.label' })}
          </Button>
        </div>
        <div className="col-7">
          <UserSwitch />
        </div>
      </header>
    </Loader>
  );
}

const UserSwitch = observer(function UserSwitch(): ReactElement {
  const {
    studentsStore,
    studentsStore: { hasPrevStudent, hasNextStudent },
    studentStore: { current, isLoading },
  } = useStores();

  const { push } = useHistory();

  return (
    <div className="UserSwitch">
      {hasPrevStudent && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            push(
              generateRoute('adminStudent', {
                id: `${studentsStore.prevStudent?.id}`,
              }),
            );
          }}
          className="UserSwitch-prev"
          disabled={isLoading}
        >
          <Icon name="chevron-left" />
        </Button>
      )}
      <div className="UserSwitch-details">
        {current?.avatar && (
          <div
            className="UserSwitch-avatar"
            style={{ backgroundImage: `url(${current.avatar})` }}
          />
        )}
        <div className="UserSwitch-detailed">
          <h2 className="UserSwitch-detailed-username">{current?.username}</h2>
          <div className="UserSwitch-detailed-email">{current?.email}</div>
        </div>
      </div>
      {hasNextStudent && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            push(
              generateRoute('adminStudent', {
                id: `${studentsStore.nextStudent?.id}`,
              }),
            );
          }}
          className="UserSwitch-next"
          disabled={isLoading}
        >
          <Icon name="chevron-right" />
        </Button>
      )}
    </div>
  );
});

function OnlyWeeklyRegular({
  weekly,
  children,
}: {
  weekly: IWeekly;
  children: ReactNode;
}): React.ReactElement | null {
  if (weekly.type !== WeeklyType.REGULAR) {
    return null;
  }

  return <>{children}</>;
}

const EvaluationForm = observer(function EvaluationForm({
  weekly,
  isOpen,
  onOpen,
  onClose,
}: {
  weekly: IWeekly;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}): React.ReactElement | null {
  const { formatMessage } = useIntl();
  const { weeklyStore } = useStores();
  const [weeklyState, setWeeklyState] = useState<{
    evaluation?: number;
    socialSkillsEvaluation?: number;
    knowledgeEvaluation?: number;
    rulesEvaluation?: number;
    comment?: string;
  }>({
    evaluation: 0,
    socialSkillsEvaluation: 0,
    knowledgeEvaluation: 0,
    rulesEvaluation: 0,
    comment: '',
  });

  useEffect(() => {
    setWeeklyState((weeklyState) => ({
      ...weeklyState,
      evaluation:
        (weeklyState.socialSkillsEvaluation || 0) * 0.4 +
        (weeklyState.knowledgeEvaluation || 0) * 0.5 +
        (weeklyState.rulesEvaluation || 0) * 0.1,
    }));
  }, [
    weekly.evaluation,
    weeklyState.rulesEvaluation,
    weeklyState.socialSkillsEvaluation,
    weeklyState.knowledgeEvaluation,
  ]);

  useEffect(() => {
    setWeeklyState({
      comment: weekly.comment,
      evaluation: weekly.evaluation,
      knowledgeEvaluation: weekly.knowledgeEvaluation,
      socialSkillsEvaluation: weekly.socialSkillsEvaluation,
      rulesEvaluation: weekly.rulesEvaluation,
    });
  }, [weekly]);

  useEffect(() => {
    const onEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', onEscape);
    return () => {
      document.removeEventListener('keydown', onEscape);
    };
  }, [onClose]);

  const isCommentValid =
    !!weeklyState.comment && weeklyState.comment.length >= 4;
  const isEvaluationValid =
    typeof weeklyState.evaluation === 'number' && weeklyState.evaluation > 0;
  const isValid = isEvaluationValid && isCommentValid;

  function onSave() {
    weeklyStore.createOrUpdate({
      ...weekly,
      socialSkillsEvaluation: weeklyState.socialSkillsEvaluation,
      knowledgeEvaluation: weeklyState.knowledgeEvaluation,
      rulesEvaluation: weeklyState.rulesEvaluation,
      comment: weeklyState.comment,
    });
  }

  function onRemove() {
    if (window.confirm(formatMessage({ id: 'common.actions.confirm.label' }))) {
      weeklyStore.delete(weekly);
      setWeeklyState({
        evaluation: 0,
        rulesEvaluation: 0,
        knowledgeEvaluation: 0,
        socialSkillsEvaluation: 0,
        comment: '',
      });
    }
  }

  return (
    <form
      className={classNames('EvaluationForm', 'row justify-content-end', {
        'EvaluationForm--open': isOpen,
      })}
      onSubmit={(event) => {
        event.preventDefault();
        onSave();
        onClose();
      }}
    >
      <OnlyWeeklyRegular weekly={weekly}>
        <div
          className={'col-6'}
          onClick={() => {
            isOpen ? onClose() : onOpen();
          }}
        >
          <EvaluationBar
            key={`${weekly.week}-${weekly.date}-${weekly.studentId}-total`}
            defaultValue={weeklyState.evaluation}
            locked={false}
            featured={weekly.featured}
            editable={false}
          />
        </div>
      </OnlyWeeklyRegular>
      <div className="col-6 align-items-center ">
        <div className="row justify-content-end">
          <OnlyWeeklyRegular weekly={weekly}>
            <div className="col-6 align-self-center EvaluationForm-miniEvaluations">
              <Popover>
                <Tooltip
                  content={formatMessage({
                    id: 'containers.students.knowledgeEvaluation.label',
                  })}
                  position={Position.TOP}
                  usePortal={false}
                  className={'EvaluationForm-commentTooltip'}
                >
                  <MiniEvaluation
                    value={weeklyState.knowledgeEvaluation}
                    featured={false}
                    locked={true}
                    icon={false}
                    showProgress={true}
                  />
                </Tooltip>
              </Popover>
              <Popover>
                <Tooltip
                  content={formatMessage({
                    id: 'containers.students.socialSkillsEvaluation.label',
                  })}
                  position={Position.TOP}
                  usePortal={false}
                  className={'EvaluationForm-commentTooltip'}
                >
                  <MiniEvaluation
                    value={weeklyState.socialSkillsEvaluation}
                    featured={false}
                    locked={true}
                    icon={false}
                    showProgress={true}
                  />
                </Tooltip>
              </Popover>
              <Popover>
                <Tooltip
                  content={formatMessage({
                    id: 'containers.students.rulesEvaluation.label',
                  })}
                  position={Position.TOP}
                  usePortal={false}
                  className={'EvaluationForm-commentTooltip'}
                >
                  <MiniEvaluation
                    value={weeklyState.rulesEvaluation}
                    featured={false}
                    locked={true}
                    icon={false}
                    showProgress={true}
                  />
                </Tooltip>
              </Popover>
            </div>
          </OnlyWeeklyRegular>
          <div className="col-6">
            <OnlyAuthorized withRoles={[ROLE_ADMIN, ROLE_TRAINER]}>
              {weekly.locked && !weekly.hidden && (
                <Button
                  intent={'danger'}
                  large
                  fill
                  onClick={onRemove}
                  className={'EvaluationForm-submit'}
                >
                  {formatMessage({
                    id: 'components.weekly.evaluation_form.delete.label',
                  })}
                </Button>
              )}
              <OnlyWeeklyRegular weekly={weekly}>
                {weekly.hidden && (
                  <Button
                    intent={'default'}
                    large
                    fill
                    disabled
                    className={
                      'EvaluationForm-submit EvaluationForm-submit--locked'
                    }
                  >
                    <Icon name="lock" />
                  </Button>
                )}
              </OnlyWeeklyRegular>
            </OnlyAuthorized>
            <OnlyWeeklyRegular weekly={weekly}>
              <OnlyAuthorized withRoles={[ROLE_ADMIN, ROLE_TRAINER]}>
                {!weekly.locked && (
                  <Button
                    type={'submit'}
                    intent={'primary'}
                    disabled={!isValid}
                    className={'EvaluationForm-submit'}
                    large
                    fill
                  >
                    {formatMessage({
                      id: 'components.weekly.evaluation_form.save.label',
                    })}
                  </Button>
                )}
              </OnlyAuthorized>
            </OnlyWeeklyRegular>
          </div>
        </div>
      </div>
      <OnlyWeeklyRegular weekly={weekly}>
        <div
          className={classNames(
            'EvaluationForm-extended',
            {
              'EvaluationForm-extended--open': isOpen,
            },
            'col-12',
          )}
        >
          <div className={'row'}>
            <div className={'col-6'}>
              <h4 className="EvaluationForm-evaluationLabel">
                {formatMessage({
                  id:
                    'containers.weekly.evaluation_form.knowledgeEvaluation.label',
                })}
              </h4>
              <EvaluationBar
                className="EvaluationForm-evaluationBar"
                key={`${weekly.week}-${weekly.date}-${weekly.studentId}-knowledge`}
                defaultValue={weekly.knowledgeEvaluation}
                locked={weekly.locked}
                featured={weekly.featured}
                editable={!weekly.locked}
                onChange={(knowledgeEvaluation) => {
                  setWeeklyState({
                    ...weeklyState,
                    knowledgeEvaluation,
                  });
                }}
              />
              <h4 className="EvaluationForm-evaluationLabel">
                {formatMessage({
                  id:
                    'containers.weekly.evaluation_form.socialSkillsEvaluation.label',
                })}
              </h4>
              <EvaluationBar
                className="EvaluationForm-evaluationBar"
                key={`${weekly.week}-${weekly.date}-${weekly.studentId}-social`}
                defaultValue={weekly.socialSkillsEvaluation}
                locked={weekly.locked}
                editable={!weekly.locked}
                onChange={(socialSkillsEvaluation) => {
                  setWeeklyState({
                    ...weeklyState,
                    socialSkillsEvaluation,
                  });
                }}
              />
              <h4 className="EvaluationForm-evaluationLabel">
                {formatMessage({
                  id: 'containers.weekly.evaluation_form.rulesEvaluation.label',
                })}
              </h4>
              <EvaluationBar
                className="EvaluationForm-evaluationBar"
                key={`${weekly.week}-${weekly.date}-${weekly.studentId}-rules`}
                defaultValue={weekly.rulesEvaluation}
                locked={weekly.locked}
                featured={weekly.featured}
                editable={!weekly.locked}
                onChange={(rulesEvaluation) => {
                  setWeeklyState({
                    ...weeklyState,
                    rulesEvaluation,
                  });
                }}
              />
            </div>
            <div className={'col-6'}>
              <h4 className="EvaluationForm-evaluationLabel">
                {formatMessage({ id: 'containers.feedback.comment.label' })}
              </h4>
              <Textarea
                textareaProps={{
                  className: classNames('EvaluationForm-comment', {
                    'EvaluationForm-comment--locked': weekly.locked,
                  }),
                  value: weeklyState.comment || '',
                  disabled: weekly.locked,
                  minLength: 4,
                  rows: 4,
                  onChange: (event) => {
                    setWeeklyState({
                      ...weeklyState,
                      comment: event.currentTarget.value,
                    });
                  },
                }}
                key={`${weekly.week}-${weekly.date}-${weekly.studentId}`}
              />
            </div>
          </div>
        </div>
      </OnlyWeeklyRegular>
    </form>
  );
});

const ManualPointSelect = Select.ofType<ManualPoint>();

const ManualPoints = observer(function ManualPoints(): ReactElement {
  const { formatMessage } = useIntl();
  const { studentStore } = useStores();
  const [selectedPoints, selectPoints] = useState<ManualPoint | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ManualPointOptions: ManualPoint[] = [
    {
      name: formatMessage(
        {
          id: 'containers.admin_student.manual_points.single_challenge',
        },
        {
          points: 150,
        },
      ),
      scoreChange: 150,
    },
    {
      name: formatMessage(
        {
          id: 'containers.admin_student.manual_points.team_challenge',
        },
        {
          points: 150,
        },
      ),
      scoreChange: 150,
    },
    {
      name: formatMessage(
        {
          id: 'containers.admin_student.manual_points.event_challenge',
        },
        {
          points: 250,
        },
      ),
      scoreChange: 250,
    },
    {
      name: formatMessage(
        {
          id: 'containers.admin_student.manual_points.extra_mile',
        },
        {
          points: 250,
        },
      ),
      scoreChange: 250,
    },
    {
      name: formatMessage(
        {
          id: 'containers.admin_student.manual_points.training',
        },
        {
          points: 200,
        },
      ),
      scoreChange: 200,
    },
    {
      name: formatMessage(
        {
          id: 'containers.admin_student.manual_points.a1_challenge',
        },
        {
          points: 500,
        },
      ),
      scoreChange: 500,
    },
    {
      name: formatMessage(
        {
          id: 'containers.admin_student.manual_points.special_field',
        },
        {
          points: 150,
        },
      ),
      scoreChange: 150,
    },
    {
      name: formatMessage(
        {
          id: 'containers.admin_student.manual_points.school_performance',
        },
        {
          points: 200,
        },
      ),
      scoreChange: 200,
    },
  ];

  return (
    <div className="ManualPoints">
      <div className="row">
        <div className="col-12">
          <h2 className="ManualPoints-header">
            {formatMessage({ id: 'containers.admin_student.heading.label' })}
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col  ManualPoints-form">
          <ManualPointSelect
            className={'ManualPoints-form-select'}
            items={ManualPointOptions}
            itemRenderer={(manualPoint, { handleClick, modifiers }) => {
              return (
                <MenuItem
                  icon={selectedPoints === manualPoint ? 'tick' : 'blank'}
                  active={modifiers.active}
                  disabled={modifiers.disabled}
                  label={`${manualPoint.name} (${formatMessage(
                    { id: 'containers.admin_student.manual_points.points' },
                    { points: manualPoint.scoreChange },
                  )})`}
                  key={manualPoint.name}
                  onClick={handleClick}
                />
              );
            }}
            onItemSelect={(manualPoint) => {
              selectPoints(manualPoint);
            }}
            itemsEqual="name"
            scrollToActiveItem
            popoverProps={{
              position: Position.TOP,
            }}
          >
            <Button intent="primary" outlined fill large>
              {selectedPoints?.name ||
                formatMessage({
                  id: 'containers.admin_student.manual_points.label',
                })}
              ...
            </Button>
          </ManualPointSelect>
          <Button
            className="ManualPoints-form-submit"
            intent="primary"
            large
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {formatMessage({ id: 'common.actions.assign.label' })}
          </Button>
          <ConfirmScoreChange
            active={isOpen}
            manualPoint={selectedPoints}
            onConfirm={() => {
              if (selectedPoints === undefined) {
                return;
              }
              studentStore
                .changeScore(selectedPoints)
                .then(() => {
                  selectPoints(undefined);
                  setIsOpen(false);
                })
                .catch((err) => console.error(err));
            }}
            onCancel={() => {
              selectPoints(undefined);
              setIsOpen(false);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col ManualPoints-list">
          {studentStore.manualPoints.map((points) => {
            const creationDate = parseDateTime(
              points.creationDate,
              ISO8601_PATTERN,
            );
            return (
              <div className="row ManualPoints-list-item" key={points.id}>
                <div className="col-4 ManualPoints-list-item-date">
                  {formatDateTime(creationDate, 'yyyy-MM-dd')} |{' '}
                  {formatDateTime(creationDate, 'HH:mm')}
                </div>
                <div className="col-8 ManualPoints-list-item-description">
                  {points.createdBy && `{${points.createdBy}}: `}
                  {points.name} (
                  {formatMessage(
                    { id: 'containers.admin_student.manual_points.points' },
                    { points: points.scoreChange },
                  )}
                  )
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

const ConfirmScoreChange = function ({
  manualPoint,
  active,
  onCancel,
  onConfirm,
}: {
  active: boolean;
  manualPoint?: ManualPoint;
  onConfirm: () => void;
  onCancel: () => void;
}): ReactElement {
  const { studentStore } = useStores();
  const { formatMessage } = useIntl();

  return (
    <div
      className={classNames('ConfirmScoreChange', {
        'ConfirmScoreChange--active': active,
      })}
    >
      <div className={'ConfirmScoreChange-content'}>
        <h2 className={'ConfirmScoreChange-title'}>
          {formatMessage({ id: 'containers.admin_student.heading.label' })}
        </h2>
        <p>
          {studentStore?.current?.username} erhält <br />
          {manualPoint?.name} (
          {formatMessage(
            { id: 'containers.admin_student.manual_points.points' },
            { points: manualPoint?.scoreChange },
          )}
          )
        </p>
        <p className={'ConfirmScoreChange-icon'}>
          <Icon name={'warning'} />
        </p>
        <p className={'ConfirmScoreChange-warning'}>
          {formatMessage({ id: 'containers.admin_student.confirm.warning' })}
        </p>
        <div className={'ConfirmScoreChange-actions'}>
          <Button outlined large onClick={() => onCancel()}>
            {formatMessage({ id: 'containers.admin_student.confirm.no' })}
          </Button>
          <Button large intent={'primary'} onClick={() => onConfirm()}>
            {formatMessage({ id: 'containers.admin_student.confirm.yes' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

const WeeklyLocker = observer(function WeeklyLocker({
  weekly,
}: {
  weekly: IWeekly;
}): ReactElement | null {
  const { weeklyStore, dailyStore } = useStores();
  const { id } = useParams();
  const weeklyDate = parseDate(weekly.date);

  if (!weeklyStore.hasExpiredDays(weeklyDate)) {
    return null;
  }

  if (weeklyStore.hasAllDaysSubmitted(weeklyDate)) {
    return null;
  }

  let intent: BadgeIntent;
  let icon: string;

  if (weeklyStore.hasUnlockedDays(weeklyDate)) {
    intent = 'default';
    icon = 'unlock';
  } else if (weeklyStore.hasLockedDays(weeklyDate)) {
    intent = 'danger';
    icon = 'lock';
  } else {
    return null;
  }

  return (
    <OnlyAuthorized withRoles={[ROLE_ADMIN]}>
      <div className="WeeklyLocker">
        <Badge
          intent={intent}
          onClick={() => {
            const action = icon === 'lock' ? 'unlock' : 'lock';
            lockDailyLogs({
              action,
              date: weekly.date,
              studentId: parseInt(id, 10),
            }).then(() => {
              weeklyStore.reload();
              dailyStore.reload();
            });
          }}
        >
          <Icon name={icon} />
        </Badge>
      </div>
    </OnlyAuthorized>
  );
});

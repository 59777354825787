import React, { ReactElement, ReactNode, useState } from 'react';
import {
  addMonths,
  eachWeekOfInterval,
  endOfMonth,
  format,
  isSameMonth,
  startOfMonth,
  subMonths,
} from 'date-fns';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Weekly, WeeklyHeader } from '../Weekly';
import { MonthSwitcher } from '../MonthSwitcher';
import {
  DefaultDateOptions,
  formatDate,
  getWeekNumber,
} from '../../lib/date.utils';

import './index.scss';

interface CalendarProps {
  defaultValue?: Date;
  onSelect?: (selected: Date) => void;
  onNext?: () => void;
  onPrev?: () => void;
  withSunday?: boolean;
  includingWeekNo?: boolean;
  selectedDates?: string[];
  missingDates?: string[];
  doneDates?: string[];
}

export const Calendar = observer(function ({
  onNext,
  onPrev,
  onSelect,
  defaultValue = new Date(),
  withSunday = true,
  includingWeekNo = true,
  selectedDates = [],
  missingDates = [],
  doneDates = [],
}: CalendarProps): ReactElement {
  const [current, setCurrent] = useState<Date>(defaultValue);
  const start = startOfMonth(current);
  const end = endOfMonth(current);
  const weeks = eachWeekOfInterval({ start, end }, DefaultDateOptions);

  return (
    <>
      <MonthSwitcher
        selected={current}
        onNext={() => {
          setCurrent(addMonths(current, 1));
          if (onNext !== undefined) onNext();
        }}
        onPrev={() => {
          setCurrent(subMonths(current, 1));
          if (onPrev !== undefined) onPrev();
        }}
      />
      <WeeklyHeader withSunday={withSunday} includingWeekNo={includingWeekNo} />
      {weeks.map((week) => {
        return (
          <Weekly
            key={getWeekNumber(week)}
            weeklyDate={week}
            withSunday={withSunday}
            includingWeekNo={includingWeekNo}
            itemRenderer={(date): ReactNode => {
              const formattedDate = formatDate(date);
              return (
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    if (onSelect !== undefined) onSelect(date);
                  }}
                  className={classNames('Day', {
                    'Day--locked': !isSameMonth(date, current),
                    'Day--selected': selectedDates.includes(formattedDate),
                    'Day--missing': missingDates.includes(formattedDate),
                    'Day--done': doneDates.includes(formattedDate),
                  })}
                >
                  {format(date, 'd')}
                </button>
              );
            }}
          />
        );
      })}
    </>
  );
});

import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { generateRoute } from '../../routes';
import { useStores } from '../../stores';
import { getDailyLog, IDailyLog } from '../../lib/client';
import { Icon } from '../../components/Icon';
import {
  formatDate,
  NAMED_MONTH_PATTERN,
  parseDate,
} from '../../lib/date.utils';
import { DailyForm } from '../../components/DailyForm';
import { DailySummary } from '../../components/DailySummary';
import { OnlyAuthorized } from '../OnlyAuthorized';

import './index.scss';

export const DailyContainer = observer(
  function DailyContainer(): ReactElement | null {
    const { date } = useParams<{
      date: string;
    }>();
    const mounted = useRef<boolean>(false);
    const { dailyStore, authStore, studentStore } = useStores();
    const { push } = useHistory();
    const [daily, setDaily] = useState<IDailyLog | undefined>();
    const empty = useMemo(() => ({ date } as IDailyLog), [date]);

    useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);

    useEffect(() => {
      if (!date) {
        return;
      }

      getDailyLog({ date })
        .then((log) => {
          mounted.current && setDaily(log);
        })
        .catch(() => {
          mounted.current && setDaily(empty);
        });

      return () => {
        // cancel();
      };
    }, [date, authStore.user, empty]);

    function onSave(daily: IDailyLog): void {
      dailyStore
        .createOrUpdate(daily)
        .then(() => {
          onCancel();
        })
        .catch((err) => console.error(err));
    }

    function onCancel(): void {
      studentStore.selectMonthByDate(selectedDate);
      push(generateRoute('studentDetails'));
    }

    const selectedDate = parseDate(date);

    if (daily === undefined) {
      return null;
    }

    const props = { date, defaultValue: daily, onCancel, onSave };
    const isFormShowable = daily && daily.studentId === daily.createdBy;
    const isSummaryShowable = daily && daily.studentId !== daily.createdBy;

    return (
      <div className="Daily row">
        <OnlyAuthorized>
          <h1 className="Daily-header col-12">
            <Icon name={'lock'} />
            {date && formatDate(selectedDate, NAMED_MONTH_PATTERN)}
          </h1>
          {isFormShowable && (
            <DailyForm {...props} readOnly={daily.done || daily.locked} />
          )}
          {isSummaryShowable && <DailySummary daily={daily} />}
        </OnlyAuthorized>
      </div>
    );
  },
);

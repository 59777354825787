import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import './index.scss';

export interface PanelProps {
  className?: string;
  children: ReactNode;
  title?: ReactNode;
}

export function Panel(props: PanelProps): ReactElement {
  const { children, title = undefined, className = '' } = props;
  return (
    <div className={classNames('Panel', className)}>
      {!!title && <h3 className="Panel-title">{title}</h3>}
      {children}
    </div>
  );
}

import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop({
  children,
}: {
  children: ReactNode;
}): ReactElement | null {
  const { pathname, search } = useLocation();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
  }, [pathname, search]);

  return <>{children}</>;
}

import React, { ReactElement } from 'react';
import classNames from 'classnames';

import './index.scss';

type IButtonProps = {
  outlined?: boolean;
  fill?: boolean;
  large?: boolean;
  intent?: 'default' | 'primary' | 'secondary' | 'danger' | 'warning';
};
type ButtonProps = IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function Button(props: ButtonProps): ReactElement {
  const {
    outlined = false,
    fill = false,
    large = false,
    intent = 'default',
    ...nativeProps
  } = props;
  const customProps: ButtonProps = {
    type: 'button',
    ...nativeProps,
    className: classNames('Button', props.className, `Button--${intent}`, {
      'Button--outlined': outlined,
      'Button--fill': fill,
      'Button--large': large,
    }),
  };
  return <button {...customProps} />;
}

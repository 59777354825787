import { RootStore } from './root.store';
import { IReactionDisposer } from 'mobx';

export abstract class AbstractStore {
  protected root: RootStore;
  protected disposers: IReactionDisposer[] = [];

  constructor(root: RootStore) {
    this.root = root;
  }

  public init(): void {
    // non empty
  }

  public dispose(): void {
    this.disposers.forEach((dispose) => dispose());
  }
}

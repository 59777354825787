import qs from 'query-string';

export type QueryParams = Record<string, string | number | undefined | string[] | number[]>;

export interface ClientRoutesMap {
  studentDetails: string;
  studentSubmitForm: string;
  feedback: string;
}

export interface AdminRoutesMap {
  admin: string;
  adminLogin: string;
  adminLogout: string;
  adminStudents: string;
  adminStudent: string;
}

export type RoutesMap = ClientRoutesMap & AdminRoutesMap;

export const AdminRoutes: AdminRoutesMap = {
  admin: '/admin/',
  adminLogin: '/admin/login',
  adminLogout: '/admin/logout',
  adminStudents: '/admin/students',
  adminStudent: '/admin/students/:id',
};

export const ClientRoutes: ClientRoutesMap = {
  studentDetails: '/',
  studentSubmitForm: '/submit/:date',

  feedback: '/feedback/:date',
};

export const DefinedRoutes: RoutesMap = {
  ...ClientRoutes,
  ...AdminRoutes,
};

export type RouteName = keyof RoutesMap;

export function generateRoute(
  name: RouteName,
  params: Record<string, string> = {},
): string {
  if (!Object.keys(DefinedRoutes).includes(name)) {
    throw new Error(`Route called "${name}" is not registered in the map`);
  }

  let path: string = DefinedRoutes[name] || '';

  Object.keys(params).forEach((key) => {
    if (path.toString().includes(`:${key}`)) {
      path = path.replace(`:${key}`, params[key]);
      delete params[key];
    }
  });

  if (!!Object.keys(params).length) {
    path = `${path}?${stringifyQueryParams(params)}`;
  }

  return path;
}

const queryStringOptions: qs.ParseOptions = {
  arrayFormat: 'index',
};

export const parseQueryParams = (url: string): qs.ParsedQuery => {
  const { query } = qs.parseUrl(url, queryStringOptions);

  return query;
};

export const stringifyQueryParams = (params: QueryParams): string => {
  return qs.stringify(params, queryStringOptions);
};

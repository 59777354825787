import {
  action,
  autorun,
  computed,
  IObservableArray,
  observable,
  ObservableMap,
  reaction,
  transaction,
} from 'mobx';
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  isBefore,
  startOfMonth,
} from 'date-fns';

import {
  Activity,
  createOrUpdateDaily,
  getActivities,
  getDailyLogs,
  getFirstMissingDailyLog,
  IDailyLog,
  removeDaily,
} from '../lib/client';
import { AbstractStore } from './abstract.store';
import { formatDate, parseDate } from '../lib/date.utils';
import { withoutEmpty } from '../lib/utils';
import { resolveDailyMode } from '../components/Weekly/functions';

class DailyStore extends AbstractStore {
  private timeout: number | undefined;

  @observable logs: ObservableMap<string, IDailyLog> = observable.map();
  @observable activities: IObservableArray<Activity> = observable.array([]);
  @observable loading = false;
  @observable missing: IDailyLog | undefined;

  public init() {
    this.disposers.push(
      reaction(
        () => [
          this.root.studentStore.current?.id,
          this.root.studentStore.criteria.startDate,
          this.root.studentStore.criteria.endDate,
        ],
        () => {
          this.load().then(console.log).catch(console.error);
        },
      ),
      reaction(
        () => [this.root.studentStore.minDate, this.root.studentStore.maxDate],
        () => {
          this.loadMissing().then(console.log).catch(console.error);
        },
      ),
      reaction(
        () => [this.root.authStore.isLoggedIn],
        () => {
          this.loadActivities().then(console.log).catch(console.error);
        },
      ),
      autorun(() => {
        if (!this.root.authStore.isLoggedIn) {
          this.logs.clear();
        }
      }),
    );
  }

  @action async load(): Promise<void> {
    const { current } = this.root.studentStore;
    if (!this.root.authStore.isLoggedIn || current === undefined) {
      return;
    }

    if (!this.root.studentStore.settings.size) {
      return;
    }

    this.startLoading();
    transaction(() => {
      getDailyLogs({
        startDate: format(
          isBefore(
            this.root.studentStore.criteria.startDate,
            this.root.studentStore.minDate,
          ) &&
            !this.root.authStore.isAdmin &&
            !this.root.authStore.isTrainer
            ? this.root.studentStore.minDate
            : this.root.studentStore.criteria.startDate,
          'yyyy-MM-dd',
        ),
        endDate: format(this.root.studentStore.criteria.endDate, 'yyyy-MM-dd'),
        studentId: current.id,
      })
        .then((logs) => {
          this.setEntries(logs);
          this.stopLoading();
        })
        .catch((e) => {
          console.error(e);
          this.setEntries({});
          this.stopLoading();
        });
    });
  }

  @action async loadMissing(): Promise<void> {
    const { current } = this.root.studentStore;
    if (!this.root.authStore.isLoggedIn || current === undefined) {
      return;
    }

    if (!this.root.studentStore.settings.size) {
      return;
    }

    this.startLoading();
    transaction(() => {
      getFirstMissingDailyLog({
        date: format(this.root.studentStore.maxDate, 'yyyy-MM-dd'),
        studentId: current.id,
      })
        .then((missingLog) => {
          this.setMissing(missingLog);
          this.stopLoading();
        })
        .catch((e) => {
          console.error(e);
          this.setMissing();
          this.stopLoading();
        });
    });
  }

  @action async loadActivities(): Promise<void> {
    if (!this.root.authStore.isLoggedIn) {
      return;
    }

    this.startLoading();
    transaction(() => {
      getActivities()
        .then(({ rows }) => {
          this.setActivities(rows);
          this.stopLoading();
        })
        .catch((e) => {
          console.error(e);
          this.setActivities([]);
          this.stopLoading();
        });
    });
  }

  @action setEntries(logs: Record<string, IDailyLog>): void {
    this.logs.clear();
    transaction(() => {
      for (const date of Object.keys(logs)) {
        this.logs.set(date, logs[date]);
      }
    });
  }

  @action setMissing(log?: IDailyLog): void {
    this.missing = log;
  }

  @action setActivities(activities: Activity[]): void {
    this.activities.clear();
    this.activities.push(...activities);
  }

  @action startLoading(): void {
    this.loading = true;
  }

  @action stopLoading(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(
      action(() => {
        this.loading = false;
      }),
      250,
    );
  }

  @computed get startDate(): Date | undefined {
    return startOfMonth(this.root.studentStore.criteria.startDate);
  }

  @computed get endDate(): Date | undefined {
    return endOfMonth(this.root.studentStore.criteria.endDate);
  }

  @computed get days(): Date[] {
    let days: Date[] = [];
    if (this.startDate && this.endDate) {
      days = eachDayOfInterval({
        start: this.startDate,
        end: this.endDate,
      });
    }

    return days;
  }

  findByDate(day: Date): IDailyLog | undefined {
    const formatted = formatDate(day);
    return this.logs.get(formatted) || undefined;
  }

  @computed get list(): Record<string, IDailyLog | undefined> {
    const results: Record<string, IDailyLog | undefined> = {};
    for (const formatted of Array.from(this.logs.keys())) {
      results[formatted] = this.logs.get(formatted) || undefined;
    }

    return results;
  }

  @computed get firstMissing(): Date | undefined {
    if (this.missing === undefined) {
      return undefined;
    }
    return parseDate(this.missing.date);
  }

  @computed get listDoneDates(): string[] {
    return Object.values(this.list)
      .filter(withoutEmpty)
      .filter((day) => resolveDailyMode(day) === 'done')
      .map((day) => day.date);
  }

  @computed get listMissingDates(): string[] {
    return Object.values(this.list)
      .filter(withoutEmpty)
      .filter((day) => resolveDailyMode(day) === 'missing')
      .map((day) => day.date);
  }

  @action createOrUpdate(daily: IDailyLog): Promise<IDailyLog> {
    if (this.root.authStore.user?.id === undefined) {
      throw new Error('You must be logged in');
    }
    const {
      studentId = this.root.authStore.user.id,
      date,
      activity,
      startedAt = '',
      finishedAt = '',
      breakStartedAt = '',
      breakFinishedAt = '',
      comment = '',
    } = daily;

    const activityType = activity?.type;

    if (activityType === undefined) {
      throw new Error('ActivityType is required');
    }

    this.startLoading();

    return new Promise((resolve) => {
      createOrUpdateDaily({
        date,
        startedAt,
        finishedAt,
        breakStartedAt,
        breakFinishedAt,
        studentId,
        activityType,
        comment,
      })
        .then(async (response) => {
          this.findOrReplace(response);
          await this.loadMissing();
          this.root.studentsStore.reload();
          this.stopLoading();
          resolve(response);
        })
        .catch((err) => {
          console.error({ err });
        });
    });
  }

  @action findOrReplace(daily: IDailyLog) {
    this.logs.delete(daily.date);
    this.logs.set(daily.date, daily);
  }

  @action reload(): void {
    this.load().then(console.log).catch(console.error);
    this.loadMissing().then(console.log).catch(console.error);
  }

  @action clear() {
    this.logs.clear();
    this.missing = undefined;
  }

  @action delete(daily: IDailyLog) {
    const { id } = daily;
    if (id === undefined) {
      return;
    }

    this.startLoading();
    removeDaily(id)
      .then(() => {
        this.remove(id);
        this.reload();
        this.stopLoading();
      })
      .catch((err) => console.error(err));
  }

  @action remove(id: number) {
    const found = Array.from(this.logs.entries()).find(
      ([, daily]) => daily.id === id,
    );

    if (found) {
      this.logs.delete(found[0]);
    }
  }
}

export { DailyStore };

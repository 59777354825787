import React, { ReactElement, ReactNode } from 'react';
import { endOfMonth } from 'date-fns';

import { formatMonthName, formatYear } from '../../lib/date.utils';
import { Button } from '../Button';
import { Icon } from '../Icon';

import './index.scss';

interface MonthSwitcherProps {
  selected: Date;
  onNext: () => void;
  onPrev: () => void;
  nextDisabled?: boolean;
  prevDisabled?: boolean;
  label?: ReactNode;
}

export function MonthSwitcher(props: MonthSwitcherProps): ReactElement {
  const {
    selected,
    onNext,
    onPrev,
    prevDisabled = false,
    nextDisabled = false,
    label = undefined,
  } = props;

  const lastDay: Date = endOfMonth(selected);

  return (
    <div className="MonthSwitcher">
      <Button
        className="MonthSwitcher-prev"
        onClick={(event) => {
          event.preventDefault();
          onPrev();
        }}
        disabled={prevDisabled}
      >
        <Icon name={'chevron-left-circle'} />
      </Button>
      <div className="MonthSwitcher-selected">
        {label === undefined &&
          `${formatMonthName(lastDay)} ${formatYear(lastDay)}`}
        {label !== undefined && label}
      </div>
      <Button
        className="MonthSwitcher-next"
        onClick={(event) => {
          event.preventDefault();
          onNext();
        }}
        disabled={nextDisabled}
      >
        <Icon name={'chevron-right-circle'} />
      </Button>
    </div>
  );
}

export default MonthSwitcher;

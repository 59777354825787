import React, { ReactElement, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { Panel } from '../Panel';
import { Icon } from '../Icon';
import { Activity, ActivityType, IDailyLog } from '../../lib/client';
import { Button } from '../Button';
import { Textarea } from '../Textarea';
import { TimeTrackingForm } from '../TimeTrackingForm';
import { useStores } from '../../stores';

import './index.scss';

export interface DailyFormProps {
  date: string;
  onSave: (log: IDailyLog) => void;
  onCancel: () => void;
  readOnly?: boolean;
  defaultValue?: IDailyLog;
}

export const DailyForm = observer(function DailyForm(
  props: DailyFormProps,
): ReactElement {
  const { date, onSave, onCancel, defaultValue, readOnly = false } = props;
  const { dailyStore } = useStores();
  const { formatMessage } = useIntl();
  const [daily, setDaily] = useState<IDailyLog | undefined>(defaultValue);
  const empty = useMemo(() => ({ ...defaultValue, date }), [
    date,
    defaultValue,
  ]);

  function Buttons(): ReactElement {
    return (
      <div className="DailyForm-buttons">
        <Button intent={'primary'} type={'submit'} large>
          {formatMessage({
            id: 'containers.student_submit_form.action.submit',
          })}
        </Button>

        <Button
          outlined
          large
          onClick={(event) => {
            event.preventDefault();
            onCancel();
          }}
        >
          {formatMessage({
            id: 'containers.student_submit_form.action.cancel',
          })}
        </Button>
      </div>
    );
  }

  function Activities(): ReactElement {
    if (readOnly && daily && daily.activity) {
      const activity = daily.activity;
      return (
        <div className="DailyForm-activities">
          <Button key={activity.id} intent={'danger'} outlined large>
            {activity.name}
          </Button>
        </div>
      );
    }

    return (
      <div className="DailyForm-activities">
        {dailyStore.activities.map((activity: Activity) => (
          <Button
            key={activity.id}
            intent={
              daily?.activity?.type === activity.type ? 'danger' : 'default'
            }
            outlined
            large
            onClick={(event) => {
              event.preventDefault();
              const newValues = {
                ...(daily || ({} as IDailyLog)),
                activity: { ...activity },
              };
              if (activity.type === ActivityType.FREE) {
                Object.assign(newValues, {
                  comment: 'frei',
                  startedAt: '08:00',
                  finishedAt: '16:00',
                  breakStartedAt: '12:00',
                  breakFinishedAt: '12:30',
                });
              }
              setDaily(newValues);
            }}
          >
            {activity.name}
          </Button>
        ))}
      </div>
    );
  }

  return (
    <form
      className={classNames('DailyForm col-12', {
        'DailyForm--readonly': readOnly,
      })}
      onSubmit={(event) => {
        event.preventDefault();
        if (daily) onSave(daily);
      }}
    >
      <Panel
        title={
          <>
            <Icon name="run" />
            {formatMessage({
              id: 'containers.student_submit_form.activity.label',
            })}
          </>
        }
      >
        <Activities />
        <Textarea
          textareaProps={{
            readOnly,
            minLength: 4,
            required: true,
            defaultValue: daily?.comment || '',
            rows: 4,
            placeholder: formatMessage({
              id: 'containers.student_submit_form.comment.placeholder',
            }),
            onChange: (event) => {
              event.preventDefault();
              setDaily({
                ...(daily || ({} as IDailyLog)),
                comment: event.currentTarget.value,
              });
            },
          }}
          label={formatMessage({
            id: 'containers.student_submit_form.comment.label',
          })}
        />
      </Panel>
      <Panel
        title={
          <>
            <Icon name="timer2" />
            {formatMessage({
              id: 'containers.student_submit_form.time.label',
            })}
          </>
        }
      >
        {daily && (
          <TimeTrackingForm
            readOnly={readOnly}
            log={{ ...daily }}
            onChange={({
              startedAt,
              finishedAt,
              breakStartedAt,
              breakFinishedAt,
            }) => {
              setDaily({
                ...(daily || empty),
                startedAt,
                finishedAt,
                breakStartedAt,
                breakFinishedAt,
              });
            }}
          />
        )}
      </Panel>
      {!readOnly && <Buttons />}
    </form>
  );
});

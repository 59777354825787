export const debounce = <BaseFunction extends (...args: any[]) => any>(
  func: BaseFunction,
  waitFor: number,
): ((...args: Parameters<BaseFunction>) => void) => {
  let timeout = 0;
  let argsCopy: any[];
  return (...args: any[]): void => {
    argsCopy = [...args];
    clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...argsCopy), waitFor);
  };
};

export function withoutEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return !!value;
}

export function convertMapToObject(map: Map<any, any>): Record<any, unknown> {
  return Array.from(map.entries()).reduce(
    (main, [key, value]) => ({ ...main, [key]: value }),
    {},
  );
}

export function exportFile(blob: Blob, suffix = Date.now(), ext = 'csv'): void {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `export-${suffix}.${ext}`;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

import React, { ReactElement } from 'react';

import './index.scss';

export function Icon({
  name,
  className = '',
  fill = false,
  title = '',
}: {
  name: string;
  className?: string;
  fill?: boolean;
  title?: string;
}): ReactElement {
  return (
    <i
      className={`Icon a1-${name} ${className} ${fill ? 'a1--fill' : ''}`}
      title={title}
    />
  );
}

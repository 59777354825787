import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { Spinner } from '@blueprintjs/core';

import './index.scss';

export function Loader({
  loading,
  children = null,
  className = '',
}: {
  loading: boolean;
  children?: ReactNode | ReactElement | null | undefined;
  className?: string;
}): ReactElement {
  return (
    <div
      className={classNames('Loader', className, {
        'Loader--loading': loading,
      })}
    >
      {loading && (
        <div className="Loader-spinner">
          <Spinner />
        </div>
      )}
      <div
        className={classNames('Loader-container ', {
          'Loader-container--frozen': loading,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default Loader;

import {
  action,
  autorun,
  computed,
  IObservableArray,
  observable,
  reaction,
} from 'mobx';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfDay,
  format,
  isBefore,
} from 'date-fns';

import {
  createOrUpdateWeekly,
  getWeeklyLogs,
  IWeekly,
  removeWeekly,
  WeeklyType,
} from '../lib/client';
import { AbstractStore } from './abstract.store';
import {
  DefaultDateOptions,
  formatDate,
  getEndOfWeek,
  getStartOfWeek,
  getWeekNumber,
  parseDate,
} from '../lib/date.utils';

class WeeklyStore extends AbstractStore {
  private timeout: number | undefined;

  @observable logs: IObservableArray<IWeekly> = observable.array([]);
  @observable loading = false;

  public init() {
    this.disposers.push(
      reaction(
        () => [
          this.root.studentStore.current?.id,
          this.root.studentStore.criteria.startDate,
          this.root.studentStore.criteria.endDate,
          this.root.studentStore.settings.values(),
        ],
        () => {
          this.load().then(console.log).catch(console.error);
        },
      ),
      autorun(() => {
        if (!this.root.authStore.isLoggedIn) {
          this.logs.clear();
        }
      }),
    );
  }

  @action async load(): Promise<void> {
    if (!this.root.authStore.isLoggedIn) {
      return;
    }
    if (this.root.studentStore.current?.id === undefined) {
      return;
    }
    if (!this.root.studentStore.settings.size) {
      return;
    }
    this.startLoading();
    getWeeklyLogs({
      startDate: format(
        this.root.studentStore.criteria.startDate,
        'yyyy-MM-dd',
      ),
      endDate: format(this.root.studentStore.criteria.endDate, 'yyyy-MM-dd'),
      studentId: this.root.studentStore.current.id,
    })
      .then(({ rows }) => {
        this.setEntries(rows);
        this.stopLoading();
      })
      .catch((e) => {
        console.error(e);
        this.setEntries([]);
        this.stopLoading();
      });
  }

  @action setEntries(weekly: IWeekly[]): void {
    this.logs.clear();
    this.logs.push(...weekly);
  }

  @action startLoading(): void {
    this.loading = true;
  }

  @action stopLoading(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(
      action(() => {
        this.loading = false;
      }),
      250,
    );
  }
  @computed get startDate(): Date | undefined {
    return getStartOfWeek(this.root.studentStore.criteria.startDate);
  }

  @computed get endDate(): Date | undefined {
    return getEndOfWeek(this.root.studentStore.criteria.endDate);
  }

  @computed get list(): Record<string, IWeekly | undefined> {
    let weeks: Date[] = [];
    if (this.startDate && this.endDate) {
      weeks = eachWeekOfInterval(
        {
          start: this.startDate,
          end: this.endDate,
        },
        DefaultDateOptions,
      );
      weeks = weeks.slice(Math.max(weeks.length - 5, 0));
    }
    const results: Record<string, IWeekly | undefined> = {};
    for (const week of weeks) {
      const weekFormatted = format(week, 'yyyy-MM-dd');
      const weekNo = getWeekNumber(week);
      results[weekFormatted] =
        this.logs.find((weekly) => {
          return weekly.week === weekNo;
        }) || this.createMissingWeek(weekFormatted);
    }

    return results;
  }

  @computed get listAsArray(): IWeekly[] {
    return [...Object.values(this.list)].filter(
      (weekly) => weekly !== undefined,
    ) as IWeekly[];
  }

  private createMissingWeek(date: string): IWeekly {
    return {
      date,
      evaluation: 0,
      socialSkillsEvaluation: 0,
      knowledgeEvaluation: 0,
      rulesEvaluation: 0,
      id: 0,
      locked: false,
      studentId: this.root.studentStore.current?.id || 0,
      teacherId: this.root.authStore.user?.id || 0,
      week: getWeekNumber(parseDate(date)),
      featured: false,
      hidden: false,
      type: WeeklyType.REGULAR,
    };
  }

  @action createOrUpdate(weekly: IWeekly) {
    const {
      locked,
      featured,
      week,
      evaluation = 0,
      comment = '',
      ...request
    } = weekly;
    this.startLoading();
    createOrUpdateWeekly({
      ...request,
      evaluation,
      comment,
    })
      .then((response) => {
        this.findOrReplace(response);
        this.root.studentsStore.reload();
        this.stopLoading();
      })
      .catch((err) => console.error(err));
  }

  @action delete(weekly: IWeekly) {
    const { id } = weekly;
    this.startLoading();
    removeWeekly(id)
      .then(() => {
        this.remove(id);
        this.root.dailyStore.reload();
        this.stopLoading();
      })
      .catch((err) => console.error(err));
  }

  @action findOrReplace(weekly: IWeekly) {
    const found = this.logs.find((e) => e.week === weekly.week);

    if (!found) {
      this.logs.push(weekly);
    } else {
      this.logs.remove(found);
      this.logs.push(weekly);
    }
  }

  @action remove(id: number) {
    const found = this.logs.find((e) => e.id === id);

    if (found) {
      this.logs.remove(found);
    }
  }

  @action reload(): void {
    this.load().then(console.log).catch(console.error);
  }

  hasLockedDays(week: Date): boolean {
    const start = getStartOfWeek(week);
    const end = getEndOfWeek(week);
    const now = endOfDay(new Date());
    if (!isBefore(end, now)) {
      return false;
    }

    const days = eachDayOfInterval({ start, end });
    for (const day of days) {
      if (this.root.dailyStore.list?.[formatDate(day)]?.locked) {
        return true;
      }
    }
    return false;
  }

  hasAllDaysSubmitted(week: Date): boolean {
    const start = getStartOfWeek(week);
    const end = getEndOfWeek(week);
    const days = eachDayOfInterval({ start, end });
    const requiredDays = 5;
    let doneDays = 0;
    for (const day of days) {
      if (this.root.dailyStore.list?.[formatDate(day)]?.done) {
        doneDays++;
      }
    }
    return doneDays >= requiredDays;
  }

  hasUnlockedDays(week: Date): boolean {
    const days = eachDayOfInterval({
      start: getStartOfWeek(week),
      end: getEndOfWeek(week),
    });
    for (const day of days) {
      if (this.root.dailyStore.list?.[formatDate(day)]?.unlockedAt) {
        return true;
      }
    }
    return false;
  }

  hasExpiredDays(week: Date): boolean {
    const days = eachDayOfInterval({
      start: getStartOfWeek(week),
      end: getEndOfWeek(week),
    });
    for (const day of days) {
      if (this.root.dailyStore.list?.[formatDate(day)]?.expired) {
        return true;
      }
    }
    return false;
  }

  @action clear() {
    this.logs.clear();
  }
}

export { WeeklyStore };

import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { IDailyLog } from '../../lib/client';
import { Icon } from '../Icon';
import { Button } from '../Button';
import { Panel } from '../Panel';
import Loader from '../Loader';

import './index.scss';

export interface DailySummaryProps {
  daily: IDailyLog;
}

export function DailySummary(props: DailySummaryProps): ReactElement {
  const {
    daily,
    daily: { activity },
  } = props;
  const { formatMessage } = useIntl();

  if (!daily || !activity) {
    return <Loader loading={true}>Loading</Loader>;
  }

  const isAuthorShowable = daily.studentId !== daily.createdBy;

  return (
    <div className="col-12">
      <Panel
        className={'DailySummary'}
        title={
          <>
            <Icon name="lock" />
            {formatMessage({
              id: 'containers.student_submit_form.activity.label',
            })}
            {isAuthorShowable && (
              <small>
                {formatMessage({
                  id: 'containers.student_submit_form.activity.by_admin',
                })}
              </small>
            )}
          </>
        }
      >
        <div>
          <Button key={activity.id} intent={'danger'} outlined large fill>
            {activity.name}
          </Button>
        </div>
      </Panel>
    </div>
  );
}

import { StudentsStore } from './students.store';
import { AuthStore } from './auth.store';
import { GroupsStore } from './groups.store';
import { StudentStore } from './student.store';
import { WeeklyStore } from './weekly.store';
import { DailyStore } from './daily.store';
import { AbstractStore } from './abstract.store';

export interface Stores {
  authStore: AuthStore;
  studentsStore: StudentsStore;
  studentStore: StudentStore;
  yearsStore: GroupsStore;
  groupsStore: GroupsStore;
  professionsStore: GroupsStore;
  levelsStore: GroupsStore;
  weeklyStore: WeeklyStore;
  dailyStore: DailyStore;
}

class RootStore {
  authStore: AuthStore & AbstractStore;
  studentsStore: StudentsStore & AbstractStore;
  studentStore: StudentStore & AbstractStore;
  yearsStore: GroupsStore & AbstractStore;
  groupsStore: GroupsStore & AbstractStore;
  professionsStore: GroupsStore & AbstractStore;
  levelsStore: GroupsStore & AbstractStore;
  weeklyStore: WeeklyStore & AbstractStore;
  dailyStore: DailyStore & AbstractStore;

  constructor() {
    // auth store need to be initialized first because the other ones use it
    this.authStore = new AuthStore(this);
    this.studentsStore = new StudentsStore(this);
    this.studentStore = new StudentStore(this);
    this.yearsStore = new GroupsStore(this, 'year');
    this.groupsStore = new GroupsStore(this, 'group');
    this.professionsStore = new GroupsStore(this, 'profession');
    this.levelsStore = new GroupsStore(this, 'level');
    this.weeklyStore = new WeeklyStore(this);
    this.dailyStore = new DailyStore(this);

    this.authStore.init();
    this.studentsStore.init();
    this.studentStore.init();
    this.yearsStore.init();
    this.groupsStore.init();
    this.professionsStore.init();
    this.levelsStore.init();
    this.weeklyStore.init();
    this.dailyStore.init();
  }

  dispose() {
    this.authStore.dispose();
    this.studentsStore.dispose();
    this.yearsStore.dispose();
    this.groupsStore.dispose();
    this.professionsStore.dispose();
    this.levelsStore.dispose();
    this.weeklyStore.dispose();
    this.dailyStore.dispose();
  }

  getStores(): Stores {
    const stores: { [key: string]: any } = {};
    for (const key of Object.getOwnPropertyNames(this)) {
      // @ts-ignore
      stores[key] = this[key];
    }
    return stores as Stores;
  }
}

const rootStore = new RootStore();

export { rootStore, RootStore };

import React, { ReactElement } from 'react';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
// @ts-ignore
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useIntl, RawIntlProvider } from 'react-intl';
import { observer } from 'mobx-react-lite';

import DummyLogging from "./components/DummyLogging";
import { MagicLinkHandler } from './containers/MagicLinkHandler';
import { OnlyAuthorized } from './containers/OnlyAuthorized';
import { ScrollToTop } from './components/ScrollToTop';
import { AdminStudents } from './containers/AdminStudents';
import { AdminStudent } from './containers/AdminStudent';
import { Header } from './containers/Header';
import { intl } from './translations';
import { ClientRoutes, DefinedRoutes, generateRoute } from './routes';
import { StudentDetailsContainer } from './containers/StudentDetails';
import { DailyContainer } from './containers/Daily';
import { Navbar } from './components/Navbar';
import { Loader } from './components/Loader';
import { WeeklyFeedbackContainer } from './containers/WeeklyFeedback';
import { AdminLogin } from './containers/AdminLogin';
import { AdminLogout } from './containers/AdminLogout';
import { Unauthorized } from './containers/Unauthorized';
import { Admin } from './containers/Admin';
import { ROLE_LEARNER } from './stores/auth.store';
import { AppLoader } from './components/AppLoader';

import './App.scss';

function TopNavigation(): ReactElement {
  const location = useLocation();
  const { formatMessage } = useIntl();
  return (
    <OnlyAuthorized>
      <Switch location={location}>
        <Route path={generateRoute('studentSubmitForm')}>
          <Navbar url={generateRoute('studentDetails')}>
            {formatMessage({ id: 'common.navigation.reports.label' })}
          </Navbar>
        </Route>
        <Route path={generateRoute('feedback')}>
          <Navbar url={generateRoute('studentDetails')}>
            {formatMessage({ id: 'common.navigation.reports.label' })}
          </Navbar>
        </Route>
      </Switch>
    </OnlyAuthorized>
  );
}

const TransitionRoute = observer(
  ({
    path,
    component,
  }: {
    path: string;
    component: React.ReactNode;
  }): ReactElement => {
    return (
      <Route path={path} key={path} exact>
        {({ match }) => (
          <CSSTransition
            in={match !== null}
            classNames="page"
            timeout={{ enter: 600, exit: 300 }}
            unmountOnExit={true}
          >
            <Loader loading={!match} className="page">
              {component}
            </Loader>
          </CSSTransition>
        )}
      </Route>
    );
  },
);

function ClientApp(): ReactElement {
  return (
    <ScrollToTop>
      <div className="App App--client container-fluid">
        <TopNavigation />
        <DummyLogging />
        <OnlyAuthorized
          withRoles={[ROLE_LEARNER]}
          fallback={
            <AppLoader>
              <Unauthorized />
            </AppLoader>
          }
        >
          <div className="page-container">
            <TransitionRoute
              path={ClientRoutes.studentDetails}
              component={<StudentDetailsContainer />}
            />
            <TransitionRoute
              path={ClientRoutes.studentSubmitForm}
              component={<DailyContainer />}
            />
            <TransitionRoute
              path={ClientRoutes.feedback}
              component={<WeeklyFeedbackContainer />}
            />
          </div>
        </OnlyAuthorized>
      </div>
    </ScrollToTop>
  );
}

function AdminApp(): ReactElement {
  const location = useLocation();

  return (
    <ScrollToTop>
      <div className="App App--admin">
        <Header />
        <TransitionGroup component={'div'}>
          <CSSTransition
            key={location.key}
            classNames="page"
            timeout={{ enter: 600, exit: 400 }}
            unmountOnExit={true}
          >
            <div className={'container App-container'}>
              <Switch location={location}>
                <Route
                  path={DefinedRoutes.adminStudent}
                  component={AdminStudent}
                />
                <Route
                  path={DefinedRoutes.adminStudents}
                  component={AdminStudents}
                />
                <Route path={DefinedRoutes.adminLogin} component={AdminLogin} />
                <Route
                  path={DefinedRoutes.adminLogout}
                  component={AdminLogout}
                />
                <Route path={DefinedRoutes.admin} component={Admin} />
                <Route path={'/admin/*'} component={Unauthorized} />
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </ScrollToTop>
  );
}

function App(): ReactElement {
  const isInIframe = window.self !== window.parent;
  const inMemoryHistory = createMemoryHistory();
  const browserHistory = createBrowserHistory();
  const appHistory = isInIframe ? inMemoryHistory : browserHistory;
  return (
    <RawIntlProvider value={intl}>
      <Router history={appHistory}>
        <MagicLinkHandler browserHistory={browserHistory}>
          <Switch>
            <Route path="/admin*">
              <AdminApp />
            </Route>
            <Route path="*">
              <ClientApp />
            </Route>
          </Switch>
        </MagicLinkHandler>
      </Router>
    </RawIntlProvider>
  );
}

export default App;

import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../stores';
import { AppLoader } from '../AppLoader';

export interface AuthorizedParams {
  children: ReactNode | ReactElement | string | undefined;
  authorizedOnly?: boolean;
  withRoles?: string[];
  withoutRoles?: string[];
  fallback?: ReactNode | ReactNode[];
}

export const Authorized = observer(
  ({
    children,
    authorizedOnly = false,
    withoutRoles = [],
    withRoles = [],
    fallback = null,
  }: AuthorizedParams): ReactElement | null => {
    const { authStore } = useStores();
    const isAuthorized =
      authStore.isLoggedIn && authStore?.user?.id !== undefined;
    const roles = authStore.user?.roles.map((role) => role.name) || [];

    if (authorizedOnly && authStore.isAuthenticating) {
      return <AppLoader>{fallback}</AppLoader>;
    }

    if (withRoles?.length) {
      for (const withRole of withRoles) {
        if (roles.includes(withRole) && authorizedOnly && isAuthorized) {
          return <>{children}</>;
        }
      }
      return <AppLoader>{fallback}</AppLoader>;
    }

    if (withoutRoles?.length) {
      for (const withoutRole of withoutRoles) {
        if (roles.includes(withoutRole) && authorizedOnly && isAuthorized) {
          return <>{fallback}</>;
        }
      }
      return <>{children}</>;
    }

    if (authorizedOnly && isAuthorized) {
      return <>{children}</>;
    }

    if (!authorizedOnly && !isAuthorized) {
      return <>{children}</>;
    }

    return <>{fallback}</>;
  },
);
